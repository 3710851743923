import SvgIcon from '@mui/material/SvgIcon'

export const Sync = (props) => (
  <SvgIcon {...props} viewBox="0 0 25 25">
    <path
      d="M12.01 4V1L8.01001 5L12.01 9V6C15.32 6 18.01 8.69 18.01 12C18.01 13.01 17.76 13.97 17.31 14.8L18.77 16.26C19.5803 14.9869 20.0104 13.5091 20.01 12C20.01 7.58 16.43 4 12.01 4ZM12.01 18C8.70001 18 6.01001 15.31 6.01001 12C6.01001 10.99 6.26001 10.03 6.71001 9.2L5.25001 7.74C4.43976 9.01309 4.00959 10.4909 4.01001 12C4.01001 16.42 7.59001 20 12.01 20V23L16.01 19L12.01 15V18Z"
      fill="#AFAFC6"
    />
  </SvgIcon>
)

export default Sync
