import SvgIcon from '@mui/material/SvgIcon'

export const Huobi = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <path
      d="M 302.332 150.395 C 302.332 79.864 264.556 19.122 235.785 -0.594 C 235.785 -0.594 233.571 -1.706 233.801 1.141 C 231.436 137.413 154.89 174.345 112.841 224.05 C 15.766 338.871 105.973 464.73 198.009 487.985 C 249.293 501.036 186.103 464.939 177.938 388.783 C 167.941 296.872 302.332 226.618 302.332 150.395 Z"
      fill="#1b2143"
    ></path>
    <path
      d="M 346.444 196.837 C 345.833 196.49 344.994 196.212 344.459 197.115 C 342.857 214.261 323.625 250.916 299.204 284.654 C 216.401 398.92 263.564 454.039 290.123 483.682 C 305.538 500.829 290.123 483.682 328.585 466.119 C 376.131 439.878 406.962 394.477 411.541 344.078 C 419.096 262.856 365.523 211.693 346.444 196.837 Z"
      fill="#2ca6e0"
    ></path>
  </SvgIcon>
)

export default Huobi
