// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (fontFamily, theme) => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 400,
  h1: {
    fontWeight: 400,
    fontSize: '2.375rem',
    lineHeight: '2.875rem',
    fontStyle: 'normal',
  },
  h2: {
    fontWeight: 400,
    fontSize: '1.875rem',
    lineHeight: '2.375rem',
    fontStyle: 'normal',
  },
  h3: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
    fontStyle: 'normal',
  },
  h4: {
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '1.875rem',
    fontStyle: 'normal',
  },
  h5: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontStyle: 'normal',
  },
  h6: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    fontStyle: 'normal',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
  },
  body1: {
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
  },
  body2: {
    fontSize: '0.813rem',
    lineHeight: '1.3125rem',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.57,
  },
  subtitle2: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  overline: {
    lineHeight: 1.66,
  },
  button: {
    textTransform: 'capitalize',
  },
  disabled: {
    color: theme.palette.grey[200],
  },
})

export default Typography
