import SvgIcon from '@mui/material/SvgIcon'

export const Bitget = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <path
      d="M 153.548 20.137 C 159.786 13.646 168.527 9.961 177.678 9.961 L 236.901 9.961 C 244.377 9.961 248.257 18.609 243.156 23.912 L 126.034 145.725 L 190.758 145.725 L 256.573 213.608 L 126.034 213.608 L 256.573 349.372 L 177.678 349.372 C 168.527 349.372 159.786 345.687 153.548 339.196 L 21.139 201.484 C 9.324 189.196 9.324 170.137 21.139 157.849 L 153.548 20.137 Z"
      fill="#1da2b4"
    ></path>
    <path
      d="M 335.572 474.958 C 329.332 481.449 320.591 485.133 311.44 485.133 L 252.217 485.133 C 244.738 485.133 240.861 476.485 245.959 471.182 L 363.084 349.37 L 298.36 349.37 L 232.545 281.486 L 363.084 281.486 L 232.545 145.723 L 311.44 145.723 C 320.591 145.723 329.332 149.407 335.572 155.898 L 467.979 293.61 C 479.794 305.898 479.794 324.958 467.979 337.246 L 335.572 474.958 Z"
      fill="#1da2b4"
    ></path>
  </SvgIcon>
)

export default Bitget
