import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
// third-party
import { Provider as ReduxProvider } from 'react-redux'

// scroll bar
import 'simplebar/dist/simplebar.css'

// apex-chart
import 'assets/third-party/apex-chart.css'
import 'assets/third-party/react-table.css'

// project import
import App from './App'
import { store } from 'store'
import { ConfigProvider } from 'contexts/ConfigContext'
import reportWebVitals from './reportWebVitals'
import { LicenseInfo } from '@mui/x-license-pro'
import dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

// dayjs.extend(dayjsBusinessDays)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(dayjsBusinessDays)

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY)

const container = document.getElementById('root')
const root = createRoot(container)

// const root = ReactDOM.createRoot(document.getElementById('root'));

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
