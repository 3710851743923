import React from 'react'
import LogoMain from 'components/logo/LogoMain'
import { Typography } from '@mui/material'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    const pattern = /Loading chunk [\d]+ failed/
    if (pattern.test(error)) {
      window.location.reload()
      return
    }
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo)
  }

  goHome = () => {
    window.location.href = '/'
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <LogoMain onClick={this.goHome} />
          <Typography variant="h5" component="h2" color="error">
            Oops! Something went wrong.
          </Typography>
          <Typography variant="body1" color="textSecondary">
            We apologize for the inconvenience. If the problem persists, please contact us.
          </Typography>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
