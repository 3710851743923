import { lazy } from 'react'

// project import
import MainLayout from 'layout/MainLayout'
import CommonLayout from 'layout/CommonLayout'
import Loadable from 'components/Loadable'
import AuthGuard from 'utils/route-guard/AuthGuard'
import { Navigate } from 'react-router'

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')))
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')))
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import('pages/maintenance/under-construction'))
)
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')))

const Dashboard = Loadable(lazy(() => import('pages/dashboard/dashboard')))
const Balances = Loadable(lazy(() => import('pages/balances/balances')))
const MarginBalances = Loadable(lazy(() => import('pages/margin-balances/margin-balances')))
const Links = Loadable(lazy(() => import('pages/links/links')))
const Portfolios = Loadable(lazy(() => import('pages/portfolios/portfolios')))
const PNLBreakdown = Loadable(lazy(() => import('pages/pnl-breakdown/pnlBreakdown')))
const HistoryPNL = Loadable(lazy(() => import('pages/history-pnl/historyPNL')))
const Imports = Loadable(lazy(() => import('pages/imports/imports')))
const HistoricalAUM = Loadable(lazy(() => import('pages/HistoricalAUM/historical_aum')))
const ComputedBalances = Loadable(lazy(() => import('pages/computedBalances/computedBalances')))
const RiskPage = Loadable(lazy(() => import('pages/risk/risk')))
const LiquidityTiers = Loadable(lazy(() => import('pages/liquidity-tiers/liquidity-tiers')))
const Operations = Loadable(lazy(() => import('pages/operations/operations')))
const Positions = Loadable(lazy(() => import('pages/positions/positions')))
const Prices = Loadable(lazy(() => import('pages/prices/prices')))
const DerivativePrices = Loadable(lazy(() => import('pages/prices/derivativePrices')))
const StressTests = Loadable(lazy(() => import('pages/stress-tests/stress-tests')))
const DailyReport = Loadable(lazy(() => import('pages/reports/dailyReport')))
const PerformanceAnalysis = Loadable(
  lazy(() => import('pages/performance-analysis/performance-analysis'))
)
const Configurations = Loadable(lazy(() => import('pages/configurations/configurations')))
const DeFi = Loadable(lazy(() => import('pages/DeFi/defi')))
const CreditAgreements = Loadable(lazy(() => import('pages/credit-agreements/credit-agreements')))
const Tranches = Loadable(lazy(() => import('pages/tranches/tranches')))
const FundingRates = Loadable(lazy(() => import('pages/funding-rates/funding-rates')))
const Reports = Loadable(lazy(() => import('pages/reports/reports')))
const ReportForm = Loadable(lazy(() => import('pages/reports/reportForm')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'portfolios',
          element: <Portfolios />,
        },
        {
          path: 'balances',
          children: [
            {
              path: 'balances',
              element: <Balances />,
            },
            {
              path: 'positions',
              element: <Positions />,
            },
            {
              path: 'margins',
              element: <MarginBalances />,
            },
            {
              path: 'defi',
              element: <DeFi />,
            },
            {
              path: 'computed-balances',
              element: <ComputedBalances />,
            },
          ],
        },
        {
          path: '/risk',
          children: [
            {
              path: 'risk',
              element: <RiskPage />,
            },
            {
              path: 'stress-test',
              element: <StressTests />,
            },
          ],
        },
        {
          path: 'performance',
          children: [
            {
              path: 'pnl-breakdown',
              element: <PNLBreakdown />,
            },
            {
              path: 'performance-analysis',
              element: <PerformanceAnalysis />,
            },
            {
              path: 'history-pnl',
              element: <HistoryPNL />,
            },
          ],
        },
        {
          path: '/transactions',
          children: [
            {
              path: 'transactions',
              element: <Operations />,
            },
          ],
        },
        {
          path: '/lms',
          children: [
            {
              path: 'credit-agreements',
              element: <CreditAgreements />,
            },
            {
              path: 'tranches',
              element: <Tranches />,
            },
          ],
        },
        {
          path: '/reports-new',
          children: [
            {
              path: 'all',
              element: <Reports />,
            },
            {
              path: 'create',
              element: <ReportForm />,
            },
            {
              path: 'edit/:reportIdToEdit',
              element: <ReportForm />,
            },
          ],
        },
        {
          path: '/prices',
          children: [
            {
              path: 'prices',
              element: <Prices />,
            },
            {
              path: 'derivatives-prices',
              element: <DerivativePrices />,
            },
            {
              path: 'funding-rates',
              element: <FundingRates />,
            },
          ],
        },
        {
          path: '/settings',
          children: [
            {
              path: 'link-management',
              element: <Links />,
            },
            {
              path: 'historicalaum',
              element: <HistoricalAUM />,
            },
            {
              path: 'liquidity-tiers',
              element: <LiquidityTiers />,
            },
            {
              path: 'imports',
              element: <Imports />,
            },
            {
              path: 'configurations',
              element: <Configurations />,
            },
          ],
        },
        {
          path: 'reports',
          element: <DailyReport />,
        },
        {
          path: '*',
          element: <Navigate to="dashboard" />,
        },
      ],
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />,
        },
        {
          path: '500',
          element: <MaintenanceError500 />,
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />,
        },
      ],
    },
  ],
}

export default MainRoutes
