import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Grid, Tooltip, Typography, CircularProgress } from '@mui/material'
import useLinks from 'hooks/models/useLinks'
import usePoll from 'hooks/usePoll'

import { ExclamationCircleOutlined, SyncOutlined, WarningOutlined } from '@ant-design/icons'
import datetolocalize from 'datetolocalize'

const createStyles = () => ({
  button: {
    cursor: 'pointer',
    color: '#98E6F0',
    '&:disabled': {
      color: '#98E6F0',
    },
  },
})
export default function LinksStatus() {
  const styles = createStyles()

  const {
    linksStatus,
    refresh,
    refetch,
    links: { data: links = [] },
  } = useLinks()

  const onRefresh = useCallback(
    async (stopPolling) => {
      const { data } = await refetch()

      if (data.some((link) => link.status === 'sync')) {
        return
      }
      stopPolling()
    },
    [refetch]
  )

  console.log(links)
  const isLinkInSync = useMemo(() => links?.some((link) => link.status === 'sync'), [links])

  const { startPolling, isPolling: isRefreshing } = usePoll(onRefresh, 5000)

  const refreshAllLinks = useCallback(async () => {
    if (isRefreshing) return
    await refresh()
    startPolling()
  }, [isRefreshing, refresh, startPolling])

  const isLinkInRefresh = useMemo(() => isLinkInSync || isRefreshing, [isLinkInSync, isRefreshing])

  useEffect(() => {
    if (isLinkInSync && !isRefreshing) {
      startPolling()
    }
  }, [isLinkInSync, isRefreshing, startPolling])

  if (!linksStatus?.time) return null
  return (
    <Grid container spacing={1} alignItems={'center'}>
      {!isLinkInRefresh && (
        <>
          <Grid item>
            <Tooltip
              title={`The last time trading data was synced into Merklebase. \n
Please note that the real-time prices of instruments are refreshed every minute.`}
              placement="top"
            >
              <ExclamationCircleOutlined />
            </Tooltip>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              {datetolocalize.dateTimeToLocale({ date: linksStatus.time })}
            </Typography>
          </Grid>
          {linksStatus.error && (
            <Grid item>
              <Tooltip
                title={`Error syncing trading data into Merklebase.
Please try refreshing again.
If the issue persists, contact us for assistance.`}
                placement="top"
              >
                <WarningOutlined style={{ color: '#FF6C5C' }} />
              </Tooltip>
            </Grid>
          )}
        </>
      )}
      <Grid item>
        <Button
          disabled={isLinkInRefresh}
          sx={styles.button}
          variant="text"
          startIcon={
            isLinkInRefresh ? (
              <CircularProgress size="14px" color="primary" />
            ) : (
              <SyncOutlined color="#98E6F0" />
            )
          }
          onClick={refreshAllLinks}
        >
          {isLinkInRefresh ? 'Refreshing...' : 'Refresh'}
        </Button>
      </Grid>
    </Grid>
  )
}
