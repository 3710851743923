// project import
import { useCallback, useMemo } from 'react'
import {
  useDisconnectLinkMutation,
  useGetLinksQuery,
  useRefreshLinkMutation,
  useGenerateEDMutation,
  useGenerateRSACSRMutation,
  useConnectLinkMutation,
  useReconnectLinkMutation,
  useEditLinkMutation,
  useGetAccountsQuery,
  usePauseLinkMutation,
} from 'store/api/merklebaseAPI'
import { mapAccounts } from 'utils/mappers/accounts'
import { mapLinks } from 'utils/mappers/links'
import usePortfolios from './usePortfolios'

// ----------------------------------------------------------------------

export default function useLinks() {
  const {
    data: linksRaw = [],
    refetch: refetchLinks,
    isLoading: isLinksLoading,
    isFetching: isLinksFetching,
  } = useGetLinksQuery()

  const {
    data: accountsRaw = [],
    isLoading: isAccountsLoading,
    isFetching: isAccountsFetching,
  } = useGetAccountsQuery({
    ignorePortfolios: true,
  })

  const { portfoliosById } = usePortfolios()

  const [disconnectLink] = useDisconnectLinkMutation()
  const [refreshLink] = useRefreshLinkMutation()
  const [generateED] = useGenerateEDMutation()
  const [generateRSACSR] = useGenerateRSACSRMutation()
  const [connectLink] = useConnectLinkMutation()
  const [reconnectLink] = useReconnectLinkMutation()
  const [pauseLink] = usePauseLinkMutation()
  const [editLink, { isFetching: isEditLinkFetching }] = useEditLinkMutation()

  const links = useMemo(() => {
    return linksRaw.map((link) => mapLinks({ link }))
  }, [linksRaw])

  const linksById = useMemo(
    () =>
      links.reduce((acc, link) => {
        acc[link.id] = link
        return acc
      }, {}),
    [links]
  )

  const accounts = useMemo(
    () =>
      accountsRaw.map((account) =>
        mapAccounts({ account, allAccounts: accountsRaw, linksById, portfoliosById })
      ),
    [accountsRaw, linksById, portfoliosById]
  )

  const connect = useCallback(
    async (params) => {
      return connectLink(params)
    },
    [connectLink]
  )

  const reconnect = useCallback(
    async (params) => {
      return reconnectLink(params)
    },
    [reconnectLink]
  )

  const edit = useCallback(
    async (params) => {
      return editLink(params)
    },
    [editLink]
  )

  const refresh = useCallback(
    async (id) => {
      const { data } = await refreshLink(id)
      return data
    },
    [refreshLink]
  )

  const remove = useCallback(
    (id) => {
      disconnectLink(id)
    },
    [disconnectLink]
  )

  const rename = useCallback((id, name) => {}, [])

  const pause = useCallback(
    async (params) => {
      const { data } = await pauseLink(params)
      return data
    },
    [pauseLink]
  )

  const ed = useCallback(async () => {
    const { data } = await generateED()
    return data
  }, [generateED])

  const rsa = useCallback(async () => {
    const { data } = await generateRSACSR()
    return data
  }, [generateRSACSR])

  const linksWithAccounts = useMemo(() => {
    const linksWithAccountsArr = []

    links.forEach((link) => {
      const availableAccounts = accounts.filter((account) => account.link?.id === link.id)
      if (availableAccounts.length > 0) {
        const linkAccounts = availableAccounts.map((account) => {
          return {
            ...link,
            // sub accounts shouldn't have a name
            name: account?.parentAccount ? '' : link?.name,
            subaccountName: account?.parentAccount ? account?.name : '',
            id: account?.id,
            ticker: account?.link?.providerTicker,
            linkId: account?.parentAccount ? '' : link?.id,
            actions: account?.parentAccount ? null : account?.id,
            number: account?.number,
            hierarchy: account?.parentAccount
              ? [account?.parentAccount.id, account?.id]
              : [account?.id],
          }
        })

        linksWithAccountsArr.push(...linkAccounts)
      } else {
        linksWithAccountsArr.push({
          ...link,
          actions: link.id,
          linkId: link?.id,
          hierarchy: [link.id],
        })
      }
    })

    return linksWithAccountsArr
  }, [accounts, links])

  const linksStatus = useMemo(() => {
    const response = {
      time: null,
      error: false,
    }

    // Filter links with status "success"
    const successLinks = links.filter(
      (link) => link.status === 'success' && link.providerTicker !== 'merklebase'
    )

    // Find the oldest lastUpdate time
    if (successLinks.length > 0) {
      const oldestLink = successLinks.reduce((oldest, current) => {
        return new Date(current.lastUpdate) < new Date(oldest.lastUpdate) ? current : oldest
      })

      response.time = oldestLink.lastUpdate
    }

    const hasInvalidStatus = links.some((link) => link.status === 'sync' || link.status === 'error')
    if (hasInvalidStatus) {
      response.error = true
    }

    return response // Return null if no link with status "success" is found
  }, [links])

  return {
    links: { data: links, isLoading: isLinksLoading || isLinksFetching },
    linksById: {
      data: linksById,
      isLoading: isLinksLoading || isLinksFetching,
    },
    linksWithAccounts: {
      data: linksWithAccounts,
      isLoading: isLinksLoading || isLinksFetching || isAccountsLoading || isAccountsFetching,
    },
    refresh,
    remove,
    rename,
    connect,
    reconnect,
    edit,
    isEditLinkFetching,
    refetch: refetchLinks,
    ed,
    rsa,
    pause,
    linksStatus,
  }
}
