import SvgIcon from '@mui/material/SvgIcon'

export const Defi = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12.2497 5.23561C12.1493 5.23561 12.0496 5.20914 11.9603 5.15665L8.48413 3.1108C8.30927 3.00796 8.20215 2.8202 8.20215 2.61757C8.20215 2.41455 8.30927 2.22718 8.48413 2.12434L11.9603 0.0787908C12.1389 -0.0262636 12.3606 -0.0262636 12.5392 0.0787908L16.0153 2.12434C16.1902 2.22718 16.2973 2.41455 16.2973 2.61757C16.2973 2.8202 16.1902 3.00796 16.0153 3.1108L12.5392 5.15665C12.4499 5.20914 12.3502 5.23561 12.2497 5.23561ZM9.90156 2.61757L12.2497 3.9996L14.5979 2.61757L12.2497 1.23592L9.90156 2.61757Z"
      fill="#6DC0F8"
    />
    <path
      d="M12.2498 9.50291C12.1494 9.50291 12.0497 9.47644 11.9604 9.42395L8.48423 7.3781C8.21193 7.21788 8.12111 6.8674 8.28111 6.59503C8.44035 6.32275 8.79006 6.22998 9.06312 6.39164L12.2498 8.2669L15.4366 6.39164C15.7081 6.22998 16.0586 6.32275 16.2186 6.59503C16.3786 6.86732 16.2877 7.21788 16.0154 7.3781L12.5393 9.42395C12.45 9.47644 12.3503 9.50291 12.2498 9.50291Z"
      fill="#6DC0F8"
    />
    <path
      d="M12.2501 9.5045C11.9346 9.5045 11.6787 9.24824 11.6787 8.93231V4.66399C11.6787 4.34806 11.9346 4.0918 12.2501 4.0918C12.5656 4.0918 12.8216 4.34806 12.8216 4.66399V8.93231C12.8216 9.24824 12.5656 9.5045 12.2501 9.5045Z"
      fill="#6DC0F8"
    />
    <path
      d="M8.77357 7.45714C8.45807 7.45714 8.20215 7.20087 8.20215 6.88495V2.61663C8.20215 2.3007 8.45807 2.04443 8.77357 2.04443C9.08907 2.04443 9.345 2.3007 9.345 2.61663V6.88495C9.345 7.20087 9.08907 7.45714 8.77357 7.45714Z"
      fill="#6DC0F8"
    />
    <path
      d="M15.7262 7.45714C15.4107 7.45714 15.1548 7.20087 15.1548 6.88495V2.61663C15.1548 2.3007 15.4107 2.04443 15.7262 2.04443C16.0417 2.04443 16.2976 2.3007 16.2976 2.61663V6.88495C16.2976 7.20087 16.0417 7.45714 15.7262 7.45714Z"
      fill="#6DC0F8"
    />
    <path
      d="M4.29758 19.7312C4.19717 19.7312 4.09743 19.7047 4.00814 19.6523L0.531979 17.6064C0.357123 17.5036 0.25 17.3158 0.25 17.1132C0.25 16.9102 0.357123 16.7228 0.531979 16.6199L4.00814 14.5744C4.18673 14.4693 4.40844 14.4693 4.58703 14.5744L8.06319 16.6199C8.23805 16.7228 8.34517 16.9102 8.34517 17.1132C8.34517 17.3158 8.23805 17.5036 8.06319 17.6064L4.58703 19.6523C4.49773 19.7047 4.39808 19.7312 4.29758 19.7312ZM1.94941 17.1132L4.29758 18.4952L6.64575 17.1132L4.29758 15.7315L1.94941 17.1132Z"
      fill="#6DC0F8"
    />
    <path
      d="M4.29769 23.9983C4.19727 23.9983 4.09754 23.9718 4.00824 23.9193L0.532084 21.8734C0.259781 21.7132 0.168963 21.3627 0.328962 21.0904C0.488198 20.8185 0.83791 20.7257 1.11097 20.887L4.29769 22.7623L7.4844 20.887C7.75671 20.7257 8.10642 20.8185 8.26642 21.0904C8.42642 21.3627 8.3356 21.7132 8.06329 21.8734L4.58713 23.9193C4.49784 23.9718 4.39818 23.9983 4.29769 23.9983Z"
      fill="#6DC0F8"
    />
    <path
      d="M4.2975 24.0001C3.982 24.0001 3.72607 23.7438 3.72607 23.4279V19.1596C3.72607 18.8437 3.982 18.5874 4.2975 18.5874C4.613 18.5874 4.86892 18.8437 4.86892 19.1596V23.4279C4.86892 23.7438 4.613 24.0001 4.2975 24.0001Z"
      fill="#6DC0F8"
    />
    <path
      d="M0.821424 21.9527C0.505922 21.9527 0.25 21.6965 0.25 21.3806V17.1122C0.25 16.7963 0.505922 16.54 0.821424 16.54C1.13693 16.54 1.39285 16.7963 1.39285 17.1122V21.3806C1.39285 21.6965 1.13693 21.9527 0.821424 21.9527Z"
      fill="#6DC0F8"
    />
    <path
      d="M7.77357 21.9527C7.45807 21.9527 7.20215 21.6965 7.20215 21.3806V17.1122C7.20215 16.7963 7.45807 16.54 7.77357 16.54C8.08907 16.54 8.345 16.7963 8.345 17.1122V21.3806C8.345 21.6965 8.08907 21.9527 7.77357 21.9527Z"
      fill="#6DC0F8"
    />
    <path
      d="M20.2024 19.7312C20.102 19.7312 20.0022 19.7047 19.9129 19.6523L16.4368 17.6064C16.2619 17.5036 16.1548 17.3158 16.1548 17.1132C16.1548 16.9102 16.2619 16.7228 16.4368 16.6199L19.9129 14.5744C20.0915 14.4693 20.3132 14.4693 20.4918 14.5744L23.968 16.6199C24.1428 16.7228 24.25 16.9102 24.25 17.1132C24.25 17.3158 24.1428 17.5036 23.968 17.6064L20.4918 19.6523C20.4025 19.7047 20.3029 19.7312 20.2024 19.7312ZM17.8542 17.1132L20.2024 18.4952L22.5505 17.1132L20.2024 15.7315L17.8542 17.1132Z"
      fill="#6DC0F8"
    />
    <path
      d="M20.2025 23.9983C20.1021 23.9983 20.0023 23.9718 19.913 23.9193L16.4369 21.8734C16.1646 21.7132 16.0737 21.3627 16.2337 21.0904C16.393 20.8185 16.7434 20.7257 17.0158 20.887L20.2025 22.7623L23.3892 20.887C23.6607 20.7257 24.0112 20.8185 24.1712 21.0904C24.3312 21.3627 24.2404 21.7132 23.9681 21.8734L20.4919 23.9193C20.4026 23.9718 20.303 23.9983 20.2025 23.9983Z"
      fill="#6DC0F8"
    />
    <path
      d="M20.2023 24.0001C19.8868 24.0001 19.6309 23.7438 19.6309 23.4279V19.1596C19.6309 18.8437 19.8868 18.5874 20.2023 18.5874C20.5178 18.5874 20.7737 18.8437 20.7737 19.1596V23.4279C20.7737 23.7438 20.5178 24.0001 20.2023 24.0001Z"
      fill="#6DC0F8"
    />
    <path
      d="M23.6784 21.9527C23.3629 21.9527 23.1069 21.6965 23.1069 21.3806V17.1122C23.1069 16.7963 23.3629 16.54 23.6784 16.54C23.9939 16.54 24.2498 16.7963 24.2498 17.1122V21.3806C24.2498 21.6965 23.9939 21.9527 23.6784 21.9527Z"
      fill="#6DC0F8"
    />
    <path
      d="M16.7262 21.9527C16.4107 21.9527 16.1548 21.6965 16.1548 21.3806V17.1122C16.1548 16.7963 16.4107 16.54 16.7262 16.54C17.0417 16.54 17.2976 16.7963 17.2976 17.1122V21.3806C17.2976 21.6965 17.0417 21.9527 16.7262 21.9527Z"
      fill="#6DC0F8"
    />
    <path
      d="M5.65865 16.4415C5.56562 16.4415 5.47114 16.4188 5.38406 16.3707C5.10726 16.2187 5.00684 15.8708 5.15861 15.5936L9.5172 7.65179C9.66897 7.37424 10.0172 7.27369 10.2933 7.42605C10.5701 7.57802 10.6705 7.92599 10.5187 8.20316L6.16013 16.145C6.05598 16.3345 5.86025 16.4415 5.65865 16.4415Z"
      fill="#6DC0F8"
    />
    <path
      d="M18.8414 16.4401C18.6398 16.4401 18.4441 16.3332 18.34 16.1435L13.9814 8.20919C13.8296 7.9324 13.93 7.58451 14.2068 7.43207C14.4821 7.27934 14.8304 7.38027 14.9829 7.65782L19.3415 15.5921C19.4932 15.8689 19.3928 16.2168 19.116 16.3693C19.0289 16.4173 18.9345 16.4401 18.8414 16.4401Z"
      fill="#6DC0F8"
    />
    <path
      d="M16.7299 20.9803H7.77015C7.45465 20.9803 7.19873 20.7241 7.19873 20.4081C7.19873 20.0922 7.45465 19.8359 7.77015 19.8359H16.7299C17.0454 19.8359 17.3013 20.0922 17.3013 20.4081C17.3013 20.7241 17.0454 20.9803 16.7299 20.9803Z"
      fill="#6DC0F8"
    />
  </SvgIcon>
)

export default Defi
