import SvgIcon from '@mui/material/SvgIcon'

export const Kcs = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <g id="layer1" transform="matrix(0.919666, 0, 0, 0.836761, 1.798535, 3.423847)">
      <g id="g6382" transform="matrix(.847 0 0 .847 102.755 165.85)">
        <path
          id="rect6336"
          class="st0"
          d="M-62.5-137.3c32.5 0 58.9 26.4 58.9 58.9v457.7c0 32.5-26.4 58.9-58.9 58.9s-58.9-26.4-58.9-58.9V-78.4c.1-32.5 26.4-58.9 58.9-58.9z"
          fill="rgb(0, 151, 232)"
        ></path>
        <path
          id="rect6338"
          class="st0"
          d="M317.8-172.9c22.9 23.1 22.7 60.4-.4 83.2L-8 232.3c-23.1 22.9-60.4 22.7-83.2-.4-22.9-23.1-22.7-60.4.4-83.2l325.4-321.9c23.1-23 60.4-22.8 83.2.3z"
          fill="rgb(0, 151, 232)"
        ></path>
        <path
          id="rect6340"
          class="st0"
          d="M317.8 473.4c22.9-23.1 22.7-60.4-.4-83.2L-8 68.3c-23.1-22.9-60.4-22.7-83.2.4-22.9 23.1-22.7 60.4.4 83.2l325.4 321.9c23.1 23 60.4 22.8 83.2-.4z"
          fill="rgb(0, 151, 232)"
        ></path>
        <path
          id="rect6361-4"
          class="st0"
          d="M495.3 226.3c22.9 23.1 22.8 60.4-.2 83.3L323.9 480c-23.1 22.9-60.4 22.8-83.3-.2-22.9-23.1-22.8-60.4.2-83.3L412 226.1c23-22.9 60.3-22.8 83.3.2z"
          fill="rgb(0, 151, 232)"
        ></path>
        <path
          id="rect6378"
          class="st0"
          d="M495.3 75c22.9-23.1 22.8-60.4-.2-83.3L323.9-178.7c-23.1-22.9-60.4-22.8-83.3.2-22.9 23.1-22.8 60.4.2 83.3L412 75.2c23.1 22.9 60.4 22.9 83.3-.2z"
          fill="rgb(0, 151, 232)"
        ></path>
        <circle
          id="path6380"
          class="st0"
          cx="282.7"
          cy="150.7"
          r="59.4"
          fill="rgb(0, 151, 232)"
        ></circle>
      </g>
    </g>
  </SvgIcon>
)

export default Kcs
