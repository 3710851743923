import SvgIcon from '@mui/material/SvgIcon'

export const Bybit = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <defs></defs>
    <path
      d="M 353.979 272.773 L 353.979 143.354 L 379.417 143.354 L 379.417 272.773 L 353.979 272.773 Z"
      fill="#f7a600"
    ></path>
    <g fill="#FFFFFF" transform="matrix(5.66053, 0, 0, 5.788733, 2.98086, 125.988823)">
      <path d="M9.634 31.998H0V9.641h9.247c4.494 0 7.112 2.45 7.112 6.28 0 2.48-1.682 4.083-2.846 4.617 1.39.627 3.168 2.04 3.168 5.024 0 4.175-2.94 6.436-7.047 6.436zm-.743-18.463H4.494v5.15H8.89c1.907 0 2.974-1.036 2.974-2.576 0-1.537-1.067-2.574-2.974-2.574zm.29 9.075H4.495v5.496h4.688c2.037 0 3.005-1.256 3.005-2.764 0-1.507-.97-2.732-3.005-2.732zM30.388 22.83v9.168h-4.462V22.83L19.007 9.641h4.882l4.3 9.012 4.235-9.012h4.881zM50.046 31.998h-9.634V9.641h9.246c4.494 0 7.113 2.45 7.113 6.28 0 2.48-1.682 4.083-2.846 4.617 1.39.627 3.168 2.04 3.168 5.024 0 4.175-2.94 6.436-7.047 6.436zm-.743-18.463h-4.397v5.15h4.397c1.907 0 2.974-1.036 2.974-2.576 0-1.537-1.067-2.574-2.974-2.574zm.29 9.075h-4.688v5.496h4.688c2.038 0 3.006-1.256 3.006-2.764 0-1.507-.968-2.732-3.006-2.732zM80.986 13.536V32h-4.494V13.535H70.48V9.641h16.52v3.894z"></path>
    </g>
  </SvgIcon>
)

export default Bybit
