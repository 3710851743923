import SvgIcon from '@mui/material/SvgIcon'

export const Binance = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <defs></defs>
    <g fill="#f3ba2f" transform="matrix(1, 0, 0, 1, 111.021431, 143.043091)">
      <path d="M 44.4 72.969 L 140.512 -21.813 L 236.663 73.047 L 292.556 17.866 L 140.512 -132.175 L -11.532 17.827 L 44.4 72.969 Z"></path>
      <path
        d="M -90.854 72.676 L -12.312 72.676 L -11.25 151.21 L -89.792 151.21 L -90.854 72.676 Z"
        transform="matrix(0.711871, -0.70231, 0.70231, 0.711871, -93.328259, -3.600322)"
      ></path>
      <path d="M 44.4 150.901 L 140.512 245.723 L 236.663 150.863 L 292.595 205.966 L 292.556 206.005 L 140.512 356.046 L -11.532 206.082 L -11.61 206.005 L 44.4 150.901 Z"></path>
      <path
        d="M 292.177 72.687 L 370.718 72.687 L 371.78 151.222 L 293.239 151.222 L 292.177 72.687 Z"
        transform="matrix(0.711871, -0.70231, 0.70231, 0.711871, 17.025814, 265.409182)"
      ></path>
      <path d="M 197.226 111.916 L 140.512 55.925 L 98.573 97.301 L 93.726 102.044 L 83.798 111.839 L 83.72 111.916 L 83.798 112.032 L 140.512 167.945 L 197.226 111.955 L 197.265 111.916 L 197.226 111.916 Z"></path>
    </g>
  </SvgIcon>
)

export default Binance
