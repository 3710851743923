import SvgIcon from '@mui/material/SvgIcon'

export const DefiWallet = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M21.8568 1.28897H2.14254C1.66844 1.28897 1.2854 1.67201 1.2854 2.14611V21.8604C1.2854 22.3345 1.66844 22.7175 2.14254 22.7175H21.8568C22.3309 22.7175 22.714 22.3345 22.714 21.8604V2.14611C22.714 1.67201 22.3309 1.28897 21.8568 1.28897ZM20.7854 13.7175H12.4283V10.289H20.7854V13.7175ZM20.7854 20.789H3.21397V3.21754H20.7854V8.57469H11.5711C11.097 8.57469 10.714 8.95772 10.714 9.43183V14.5747C10.714 15.0488 11.097 15.4318 11.5711 15.4318H20.7854V20.789ZM13.8211 12.0033C13.8211 12.2874 13.934 12.5599 14.1349 12.7609C14.3359 12.9618 14.6084 13.0747 14.8925 13.0747C15.1767 13.0747 15.4492 12.9618 15.6502 12.7609C15.8511 12.5599 15.964 12.2874 15.964 12.0033C15.964 11.7191 15.8511 11.4466 15.6502 11.2456C15.4492 11.0447 15.1767 10.9318 14.8925 10.9318C14.6084 10.9318 14.3359 11.0447 14.1349 11.2456C13.934 11.4466 13.8211 11.7191 13.8211 12.0033Z"
      fill="url(#paint0_linear_165_2212)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_165_2212"
        x1="11.9997"
        y1="1.28897"
        x2="11.9997"
        y2="22.7175"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#1896A7" />
      </linearGradient>
    </defs>
  </SvgIcon>
)

export default DefiWallet
