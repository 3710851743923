import SvgIcon from '@mui/material/SvgIcon'

export const Amber = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <g
      xmlns="http://www.w3.org/2000/svg"
      transform="matrix(1.549679, 0, 0, -1.542847, 1.675928, 496.893188)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M17 302 c-14 -15 -17 -41 -17 -144 0 -163 -5 -158 162 -158 163 0 158 -5 158 162 0 163 5 158 -162 158 -108 0 -127 -2 -141 -18z m197 -127 c52 -121 51 -115 29 -115 -15 0 -26 17 -47 70 -15 38 -31 72 -35 74 -3 2 -19 -27 -35 -66 -26 -63 -48 -89 -62 -76 -2 3 14 49 37 102 53 124 64 125 113 11z"
        fill="#FFFFFF"
      ></path>
    </g>
  </SvgIcon>
)

export default Amber
