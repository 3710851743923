import SvgIcon from '@mui/material/SvgIcon'

export const Crypto = (props) => (
  <SvgIcon {...props} viewBox="0 0 36 36">
    <g opacity="0.5">
      <path
        d="M35.4614 22.3543C33.0571 31.9972 23.2893 37.8658 13.6441 35.4611C4.00295 33.0571 -1.86622 23.2897 0.539134 13.6476C2.94238 4.00354 12.7102 -1.86541 22.3524 0.538621C31.9969 2.94265 37.8657 12.7111 35.4612 22.3545L35.4613 22.3543H35.4614Z"
        fill="#F7931A"
      />
      <path
        d="M25.9381 15.4354C26.2964 13.04 24.4725 11.7524 21.9785 10.8934L22.7876 7.64863L20.8121 7.15645L20.0245 10.3158C19.5052 10.1863 18.9719 10.0643 18.4418 9.9433L19.2351 6.76309L17.261 6.27091L16.4514 9.51465C16.0217 9.41681 15.5996 9.32012 15.1901 9.21823L15.1923 9.20803L12.4682 8.52784L11.9427 10.6375C11.9427 10.6375 13.4083 10.9734 13.3774 10.9941C14.1773 11.1937 14.322 11.7232 14.298 12.1429L13.3764 15.8394C13.4314 15.8534 13.5029 15.8737 13.5817 15.9053C13.5158 15.889 13.4457 15.8711 13.3729 15.8537L12.0811 21.0321C11.9833 21.2751 11.7352 21.6398 11.1759 21.5013C11.1957 21.53 9.74016 21.143 9.74016 21.143L8.7594 23.404L11.3301 24.0448C11.8083 24.1647 12.277 24.2901 12.7385 24.4081L11.9211 27.6901L13.8942 28.1823L14.7037 24.9351C15.2428 25.0815 15.7659 25.2164 16.278 25.3436L15.4712 28.5755L17.4467 29.0677L18.2641 25.7918C21.6326 26.4293 24.1654 26.1723 25.2316 23.1258C26.0906 20.673 25.1888 19.2583 23.4166 18.3357C24.7074 18.038 25.6796 17.1892 25.9388 15.4357L25.9382 15.4352L25.9381 15.4354ZM21.4247 21.7636C20.8143 24.2164 16.6841 22.8905 15.345 22.558L16.4298 18.2099C17.7688 18.5442 22.0628 19.2056 21.4248 21.7636H21.4247ZM22.0357 15.3999C21.4788 17.6309 18.0412 16.4975 16.9261 16.2195L17.9095 12.2761C19.0247 12.554 22.6158 13.0727 22.0359 15.3999H22.0357Z"
        fill="white"
      />
    </g>
  </SvgIcon>
)

export default Crypto
