import SvgIcon from '@mui/material/SvgIcon'

export const ArrowUp = (props) => (
  <SvgIcon {...props} viewBox="0 0 12 8">
    <path
      d="M6 0.300119L12 6.30012L10.6 7.70012L6 3.10012L1.4 7.70012L0 6.30012L6 0.300119Z"
      fill="#AFAFC6"
    />
  </SvgIcon>
)

export default ArrowUp
