import SvgIcon from '@mui/material/SvgIcon'

export const Remove = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M16.0637 8.29358C16.0637 8.19045 15.9794 8.10608 15.8762 8.10608L14.3294 8.11311L11.9997 10.8905L9.67233 8.11545L8.12311 8.10842C8.01998 8.10842 7.93561 8.19045 7.93561 8.29592C7.93561 8.34045 7.95201 8.38264 7.98014 8.4178L11.0294 12.0506L7.98014 15.6811C7.95182 15.7154 7.9361 15.7584 7.93561 15.803C7.93561 15.9061 8.01998 15.9905 8.12311 15.9905L9.67233 15.9834L11.9997 13.2061L14.327 15.9811L15.8739 15.9881C15.977 15.9881 16.0614 15.9061 16.0614 15.8006C16.0614 15.7561 16.045 15.7139 16.0169 15.6787L12.9723 12.0483L16.0215 8.41545C16.0497 8.38264 16.0637 8.33811 16.0637 8.29358Z"
      fill="#FD7971"
    />
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
      fill="#FD7971"
    />
  </SvgIcon>
)

export default Remove
