import { openSnackbar as openSnackbarAction } from 'store/reducers/snackbar'
import { useDispatch } from 'store'

export default function useSnackbar() {
  const dispatch = useDispatch()

  const openSnackbar = ({ message, variant = 'alert', color = 'success', close = false }) => {
    dispatch(
      openSnackbarAction({
        open: true,
        message,
        variant,
        alert: {
          color,
        },
        close,
      })
    )
  }

  return {
    openSnackbar,
  }
}
