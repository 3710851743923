// ==============================|| OVERRIDES - TOGGLE BUTTON ||============================== //

export default function ToggleButton(theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          padding: '3px 9px',
          borderWidth: 0,
          '&.Mui-selected': {
            backgroundColor: theme.palette.background.paper,
            borderRadius: `4px !important`,
            border: `0.5px solid ${theme.palette.primary.darker} !important`,
            color: theme.palette.primary.darker,
          },
          '&:hover': {
            backgroundColor: theme.palette.background.active,
          },
          '&.Mui-disabled': {
            // borderColor: theme.palette.divider,
            borderWidth: 0,
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.background.paper,
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
          '&:fullWidth': {
            borderRadius: 4,
          },
        },
      },
    },
  }
}
