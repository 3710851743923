import SvgIcon from '@mui/material/SvgIcon'

export const Bitfinex = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <path
      d="M 28.13 414.341 C 50.524 437.362 173.667 547.663 366.937 419.643 C 506.724 314.147 503.217 85.434 489.8 16.327 C 485.125 26.387 322.944 370.974 28.13 414.341 Z M 489.8 16.281 C 488.07 15.602 308.264 -8.144 130.515 102.836 C 20.135 171.717 5.502 272.591 10.551 339.977 C 268.946 311.79 483.909 24.212 489.8 16.281 Z"
      fill="#97c554"
    ></path>
    <path
      d="M 489.8 16.281 C 488.07 15.602 308.264 -8.144 130.515 102.836 C 20.135 171.717 5.502 272.591 10.551 339.977 C 268.946 311.79 483.909 24.212 489.8 16.281 Z"
      fill="#709b30"
    ></path>
  </SvgIcon>
)

export default Bitfinex
