export const mapPortfolios = ({ portfolio, selectedPortfoliosIds }) => ({
  id: portfolio.id,
  name: portfolio.name,
  description: portfolio.description,
  location: portfolio.location,
  portfolioValue: portfolio.portfolioValue,
  mappedAccounts: portfolio.accounts_ids?.length || 0,
  accountsIds: portfolio.accounts_ids || [],
  portfolioIds: portfolio.portfolio_ids || [],
  isSelected: selectedPortfoliosIds?.includes(portfolio.id),
  isCounterparty: portfolio.is_external_entity,
})
