// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 46,
          color: theme.palette.text.secondary,
          borderRadius: 4,
          '&:hover': {
            backgroundColor: theme.palette.background.active,
            color: theme.palette.text.primary,
            borderRadius: 0,
          },
          '&.Mui-selected': {
            color: theme.palette.primary.darker,
            '&:hover': {
              backgroundColor: theme.palette.background.active,
            },
          },
          '&:focus-visible': {
            borderRadius: 4,
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: -3,
          },
        },
      },
    },
  }
}
