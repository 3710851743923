import SvgIcon from '@mui/material/SvgIcon'

export const Rename = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M5.18832 18.4261C5.2419 18.4261 5.29547 18.4207 5.34904 18.4127L9.85439 17.6225C9.90797 17.6118 9.95886 17.5877 9.99636 17.5475L21.3508 6.19302C21.3757 6.16824 21.3954 6.13881 21.4088 6.10641C21.4222 6.074 21.4292 6.03927 21.4292 6.00419C21.4292 5.9691 21.4222 5.93437 21.4088 5.90196C21.3954 5.86956 21.3757 5.84013 21.3508 5.81535L16.899 1.36088C16.8481 1.30999 16.7812 1.2832 16.7089 1.2832C16.6365 1.2832 16.5696 1.30999 16.5187 1.36088L5.16422 12.7153C5.12404 12.7555 5.09993 12.8037 5.08922 12.8573L4.29904 17.3627C4.27298 17.5062 4.28229 17.6538 4.32616 17.7929C4.37004 17.932 4.44715 18.0583 4.55082 18.1609C4.72761 18.3323 4.94993 18.4261 5.18832 18.4261ZM6.99368 13.7546L16.7089 4.04213L18.6723 6.00552L8.95707 15.718L6.57582 16.1386L6.99368 13.7546ZM21.8571 20.6761H2.14279C1.66868 20.6761 1.28564 21.0591 1.28564 21.5332V22.4975C1.28564 22.6153 1.38207 22.7118 1.49993 22.7118H22.4999C22.6178 22.7118 22.7142 22.6153 22.7142 22.4975V21.5332C22.7142 21.0591 22.3312 20.6761 21.8571 20.6761Z"
      fill="#DAD8F8"
    />
  </SvgIcon>
)

export default Rename
