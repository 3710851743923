import SvgIcon from '@mui/material/SvgIcon'

export const Bitgo = (props) => (
  <SvgIcon {...props} viewBox="0 0 1024 1024">
    <g xmlns="http://www.w3.org/2000/svg" id="Icon">
      <circle cx="512" cy="512" r="512" fill="#009fe8" />
      <path
        class="st1"
        d="M553.1 467c.3-29.8-44.5-25.7-58.5-25.7v50.3c14 .4 58.1 3.8 58.5-24.6zm-58.9 50v55.4c16.8 0 69.8 3.8 70.2-27.4.3-32.5-53.4-28-70.2-28z"
      />
      <path
        class="st1"
        d="M711.9 301.7c-39.7-1.7-133.8-9.9-199.9-45.2-66.1 35.3-160.2 43.5-199.9 45.2-10.6.7-18.8 9.6-18.8 20.2v98.2c0 273.1 218.7 347.4 218.7 347.4s218.7-74.3 218.7-347.4v-98.2c0-10.6-8.2-19.5-18.8-20.2zM614 550.2c-4.1 39.7-33.5 50.3-76 52.4v41.8h-25.3v-41.1c-6.5 0-13.3 0-20.2-.3v41.4h-25v-41.8c-5.8-.3-45.9 0-45.9 0l.3-30.5h28.4V438.9h-32.2v-26.4s45.2-.3 50.7-.3l-.3-41.4h25.3v40.7h20.5v-40.4h25.3v41.8c32.5 3.1 57.8 13.3 60.6 43.1 2.1 21.9-7.2 34.9-21.9 41.8 24.1 5.8 38.8 20.5 35.7 52.4z"
      />
    </g>
  </SvgIcon>
)

export default Bitgo
