import SvgIcon from '@mui/material/SvgIcon'

export const Moonbeam = (props) => (
  <SvgIcon {...props} viewBox="0 0 2500 2500">
    <g id="Layer_x0020_1">
      <g id="_2167087048384">
        <rect y="0" class="st0" fill="none" width="2500" height="2500" />
        <g>
          <path
            class="st1"
            fill="#E1177C"
            d="M1723,2214c30,0,56,17,56,39s-26,39-56,39H903c-30,0-56-17-56-39s26-39,56-39H1723z"
          />
          <circle class="st1" fill="#E1177C" cx="725" cy="2235" r="56" />
          <circle class="st1" fill="#E1177C" cx="1218" cy="2028" r="56" />
          <path
            class="st1"
            fill="#E1177C"
            d="M2180,1972c30,0,56,26,56,56s-26,56-56,56h-803c-30,0-56-26-56-56s26-56,56-56H2180z"
          />
          <circle class="st1" fill="#E1177C" cx="164" cy="1821" r="56" />
          <path
            class="st1"
            fill="#E1177C"
            d="M963,1765c30,0,56,26,56,56s-26,56-56,56H302c-30,0-56-26-56-56s26-56,56-56H963z"
          />
          <path
            class="st1"
            fill="#E1177C"
            d="M2444,1765c30,0,56,26,56,56s-26,56-56,56H1131c-30,0-56-26-56-56s26-56,56-56H2444z"
          />
          <path
            class="st1"
            fill="#E1177C"
            d="M2198,1557c30,0,56,26,56,56s-26,56-56,56H587c-30,0-56-26-56-56s26-56,56-56H2198z"
          />
          <circle class="st1" fill="#E1177C" cx="410" cy="1613" r="56" />
          <path
            class="st1"
            fill="#E1177C"
            d="M1861,1354c30,0,56,26,56,56s-26,56-56,56H250c-30,0-56-26-56-56s26-56,56-56H1861z"
          />
          <circle class="st1" fill="#E1177C" cx="56" cy="1411" r="56" />
          <circle class="st1" fill="#E1177C" cx="410" cy="1203" r="56" />
          <path
            class="st1"
            fill="#E1177C"
            d="M2353,1147c30,0,56,26,56,56s-26,56-56,56H600c-30,0-56-26-56-56s26-56,56-56H2353z"
          />
          <path
            class="st2"
            fill="#53CBC9"
            d="M1744,271c285,121,475,419,453,721c-510,0-1019,4-1529-4c0-203,69-410,216-553c216-220,574-289,859-164H1744     z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Moonbeam
