import SvgIcon from '@mui/material/SvgIcon'

export const Bitmex = (props) => (
  <SvgIcon {...props} viewBox="0 0 1262.08 205.41">
    <path
      d="m107.52 3.85h118.16a7.8 7.8 0 0 1 -1.55 4.32c-4 6.86-7.6 14-11.53 20.86-5.56 9.85-10.76 19.88-16.41 29.69-3.58 7.06-7.62 13.9-11.44 20.84q-34.44 0-68.88 0c-1.2 0-2.39.06-3.59.13-1 2.64-2.66 4.95-4 7.43-1.75 2.84-3.17 5.87-4.9 8.71-1.83 3.82-4.36 7.24-6.2 11.05-3.8 6.45-7.35 13-11.07 19.52q-5.82 10-11.38 20.28a86 86 0 0 0 -4.33 7.51c-7.77 13.52-15.29 27.16-23.07 40.67a16.32 16.32 0 0 1 -2.58 4c-13.3-.09-26.6 0-39.9 0a20.41 20.41 0 0 0 -4.85.68c0-3.68 2.46-6.63 4.12-9.72 2-4.15 4.67-8 6.68-12.16 15.94-28.52 31.71-57.14 47.6-85.68 3.6-6.2 6.69-12.53 10.31-18.61 4.52-8 8.73-16.18 13.44-24.09 1.5-3.31 3.52-6.34 5.17-9.57 5.86-10.43 11.61-20.92 17.45-31.35a18.23 18.23 0 0 1 2.75-4.51z"
      fill="#eb4a60"
    />
    <g fill="#595fb5">
      <path d="m244.44 45.84c7.87-13.75 15.47-27.65 23.45-41.34h42.68a8.82 8.82 0 0 1 1.84.29c-1.42 2.15-2.61 4.43-3.86 6.67q-15.44 27.71-30.79 55.45c-4.3 7.53-8.3 15.23-12.66 22.72l-.43.15-.08.94q-30.1 54-60 108.08-58.77.06-117.55 0c2.23-4.66 4.87-9.11 7.35-13.64 11.16-20.52 22.61-40.86 33.61-61.38q36.11-.06 72.22 0c14.87-25.9 29.42-52 44.18-77.94z" />
      <path d="m684.91 3.97v197.2h34.17v-138.67l62.33 91.33 61.83-93.5v140.84h35.17v-197.2h-37.83l-58.92 91.03-59.58-91.03z" />
      <path d="m525.08 54.83h22.33v148h-22.33z" />
      <path d="m523.16 0h26.17v24.83h-26.17z" />
      <path d="m590.08 12.42h23.33v42.41h46v20.67h-46v87.92a20.85 20.85 0 0 0 2.29 9.75c3.95 7.5 14.68 16.4 43.71 6v19.16s-26.5 15-50.5 1.34c0 0-11.92-5.06-16.93-22.14a49.85 49.85 0 0 1 -1.9-14.07v-88.29l-19.67.33v-20.67h19.83z" />
      <path d="m914.41 3.97v198.53h148.33v-32h-112.16v-52.67h98.83v-31.5h-99v-50.16h111.17v-32.2z" />
      <path d="m1076.66 3.97h42.25l48.5 69.7 48.12-69.7h42.25l-69 96.82 73.3 101.54h-44.5l-50-74.16-52.42 74.33h-42.25l72.25-100.83z" />
      <path d="m456.74 99.5s32.67-12.33 30.67-47.83c0 0-.27-34.14-38.71-44.68a74 74 0 0 0 -19.6-2.52h-92.19l.25 197.2h99.25s58.08-1.25 62.17-53c0 0 4.16-36.17-41.84-49.17zm-96-74.67h58.84c46.16-.16 44.83 32.34 44.83 32.34.69 37.08-41.67 36.33-41.67 36.33h-62zm72 156h-72v-68.66h68.84c46.16-.17 44.83 32.33 44.83 32.33.69 37.08-41.67 36.33-41.67 36.33z" />
    </g>
  </SvgIcon>
)

export default Bitmex
