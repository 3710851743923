import SvgIcon from '@mui/material/SvgIcon'

export const ArrowDown = (props) => (
  <SvgIcon {...props} viewBox="0 0 12 8">
    <path
      d="M6 7.70012L0 1.70012L1.4 0.300121L6 4.90012L10.6 0.300121L12 1.70012L6 7.70012Z"
      fill="#AFAFC6"
    />
  </SvgIcon>
)

export default ArrowDown
