// project import
import Routes from 'routes'
import ThemeCustomization from 'themes'
import Locales from 'components/Locales'
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop'
import Snackbar from 'components/@extended/Snackbar'
import Notistack from 'components/third-party/Notistack'

// auth-provider
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext'
import { datadogRum } from '@datadog/browser-rum'
import ErrorBoundary from 'pages/ErrorBoundary'

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

if (
  process.env.REACT_APP_DATADOG_APPLICATION_ID &&
  process.env.REACT_APP_DATADOG_APPLICATION_ID !== 'none' &&
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN !== 'none'
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'merklebase-app',
    env: 'prod',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

const App = () => {
  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  )
}

export default App
