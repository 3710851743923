import SvgIcon from '@mui/material/SvgIcon'

export const Kraken = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <path
      d="M 234.162 39.915 C 276.745 37.145 320.129 45.8 358.477 64.955 C 414.91 92.881 459.897 143.424 481.189 203.084 C 490.575 229.394 495.612 257.32 495.612 285.246 L 495.612 389.102 C 495.612 393.371 495.726 397.641 494.696 401.795 C 492.407 412.296 484.966 421.528 475.236 425.913 C 468.482 429.144 460.699 429.49 453.372 428.336 C 437.69 425.451 425.442 410.565 425.327 394.525 C 425.213 362.33 425.327 330.134 425.327 297.824 C 425.556 288.707 425.671 278.899 420.748 270.821 C 412.735 255.935 393.504 248.896 377.937 255.243 C 364.772 259.974 355.271 273.36 355.157 287.438 C 355.043 320.21 355.157 352.867 355.157 385.524 C 355.157 391.64 355.5 397.872 353.669 403.757 C 350.349 415.296 340.848 424.643 329.401 427.759 C 314.406 431.913 297.121 425.22 289.566 411.257 C 284.414 402.834 284.758 392.679 284.872 383.216 C 284.758 350.79 284.987 318.249 284.758 285.822 C 284.414 269.321 271.021 254.435 254.767 252.358 C 244.235 250.742 233.132 254.32 225.462 261.82 C 218.594 268.398 214.588 277.86 214.588 287.438 L 214.588 382.062 C 214.473 390.025 215.389 398.218 212.413 405.718 C 207.719 419.22 194.212 429.144 179.903 428.798 C 165.251 429.605 151.057 419.451 146.363 405.603 C 143.73 398.795 144.303 391.409 144.303 384.255 C 144.303 351.598 144.417 318.941 144.303 286.284 C 144.188 270.129 131.711 255.358 116.029 252.473 C 101.72 249.357 85.923 256.627 78.826 269.552 C 75.735 274.975 74.133 281.207 74.133 287.323 L 74.133 393.371 C 74.247 403.641 69.554 413.912 61.655 420.489 C 49.75 430.99 30.519 431.567 18.042 421.874 C 8.999 415.642 3.619 404.911 3.619 393.833 L 3.619 286.399 C 3.733 245.78 13.921 205.276 33.152 169.504 C 48.835 140.309 70.47 114.345 96.34 93.573 C 135.26 61.955 184.138 42.915 234.162 39.915 Z"
      id="_x23_0da8ffff"
      fill="rgb(13, 168, 255)"
    ></path>
  </SvgIcon>
)

export default Kraken
