// ==============================|| OVERRIDES - INPUT BASE ||============================== //

export default function InputBase(theme) {
  const { palette } = theme

  return {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.75rem',
        },
        root: {
          '& .Mui-disabled': {
            color: palette.grey[300],
            WebkitTextFillColor: palette.grey[300],
          },
          '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: palette.grey[300],
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
  }
}
