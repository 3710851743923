import { useMemo, useRef, useState } from 'react'

import { ExclamationCircleOutlined, UpOutlined } from '@ant-design/icons'
import usePortfolios from 'hooks/models/usePortfolios'

// material-ui
import {
  useTheme,
  Popper,
  Paper,
  ClickAwayListener,
  CardContent,
  List,
  Checkbox,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Grid,
  Button,
  Typography,
  Tooltip,
} from '@mui/material'
import { DownOutlined } from '@ant-design/icons'

// project import
import Transitions from 'components/@extended/Transitions'
import MainCard from 'components/MainCard'
import { useCallback } from 'react'

const createStyles = (theme) => ({
  popper: {
    zIndex: 2001,
  },
  paper: {
    boxShadow: theme.customShadows.z1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
  },
  cardContent: {
    '&:last-child': {
      p: 0,
    },
    backgroundColor: theme.palette.background.default,
    p: 0,
    width: 150,
  },
  listItemButton: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})

export const PortfolioSelect = () => {
  const theme = useTheme()
  const styles = createStyles(theme)

  const [isPortfoliosMenuOpen, setIsPortfoliosMenuOpen] = useState(false)

  const anchorRef = useRef(null)

  const {
    selectedPortfolios: { data: selectedPortfolios },
    updateSelectedPortfolios: { mutation: updateSelectedPortfolios },
    portfolios: { data: portfolios },
    portfoliosById: { data: portfoliosById },
  } = usePortfolios()

  const allPortfolios = useMemo(
    () => portfolios.filter((portfolio) => portfolio.isCounterparty === false) || [],
    [portfolios]
  )

  const getIntersectedPortfolio = useCallback(
    (id) => {
      const portfolio = portfoliosById[id]
      const portfolioAccountsIds = portfolio?.accountsIds || []

      const intersectedPortfolio = selectedPortfolios?.find((selectedPortfolio) => {
        if (selectedPortfolio.id === id) return false
        const pAccountsIds =
          allPortfolios.find((p) => p.id === selectedPortfolio.id)?.accountsIds || []
        return pAccountsIds.some((pAccountId) => portfolioAccountsIds.includes(pAccountId))
      })

      return intersectedPortfolio
    },
    [allPortfolios, portfoliosById, selectedPortfolios]
  )

  const onPortfolioSelect = useCallback(
    (id) => {
      if (selectedPortfolios?.some((selectedPortfolio) => selectedPortfolio.id === id)) {
        updateSelectedPortfolios(selectedPortfolios.map((p) => p.id).filter((p) => p !== id))
      } else {
        updateSelectedPortfolios([...selectedPortfolios.map((p) => p.id), id])
      }
    },
    [selectedPortfolios, updateSelectedPortfolios]
  )

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setIsPortfoliosMenuOpen((prev) => !prev)}
        variant="outlined"
        color="secondary"
        endIcon={isPortfoliosMenuOpen ? <UpOutlined /> : <DownOutlined />}
      >
        <Typography variant="subtitle1">
          {selectedPortfolios?.length
            ? selectedPortfolios?.length > 1
              ? `${selectedPortfolios[0].name}...`
              : selectedPortfolios[0].name
            : 'No portfolios selected'}
        </Typography>
      </Button>
      <Popper
        placement="bottom-end"
        open={isPortfoliosMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={styles.popper}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={isPortfoliosMenuOpen}
            {...TransitionProps}
          >
            <Paper sx={styles.paper}>
              <ClickAwayListener onClickAway={() => setIsPortfoliosMenuOpen(false)}>
                <MainCard elevation={0} border={false} content={false}>
                  <CardContent sx={styles.cardContent}>
                    <List>
                      {allPortfolios
                        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                        .map(({ id, name }) => (
                          <ListItem key={id} disablePadding>
                            <ListItemButton
                              role={undefined}
                              dense
                              disabled={!!getIntersectedPortfolio(id)}
                              onClick={() => !getIntersectedPortfolio(id) && onPortfolioSelect(id)}
                              sx={styles.listItemButton}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  value={id}
                                  edge="start"
                                  checked={selectedPortfolios.map((p) => p.id)?.includes(id)}
                                  disableRipple
                                />
                              </ListItemIcon>
                              <ListItemText id={`checkbox-list-label-${id}`}>
                                <Grid container alignItems={'center'} columnSpacing={1}>
                                  <Grid item>{name}</Grid>
                                  {!!getIntersectedPortfolio(id) && (
                                    <Tooltip
                                      key={id}
                                      title={`You can't select this portfolio and ${
                                        getIntersectedPortfolio(id)?.name
                                      }, since both portfolios are mapped to the same accounts.`}
                                      placement={'right'}
                                    >
                                      <Grid item>
                                        <ExclamationCircleOutlined
                                          style={{ color: theme.palette.error.main }}
                                        />
                                      </Grid>
                                    </Tooltip>
                                  )}
                                </Grid>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        ))}
                    </List>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default PortfolioSelect
