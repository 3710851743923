import SvgIcon from '@mui/material/SvgIcon'

export const Anchorage = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M21.0832 6.60638C19.7984 4.38238 17.7504 2.70077 15.3184 1.87517C12.8864 1.04958 10.2368 1.13597 7.86399 2.11997C5.49119 3.10237 3.55679 4.91517 2.42079 7.21917C1.28479 9.52317 1.02559 12.16 1.68959 14.6416C2.35519 17.1232 3.89919 19.2768 6.03359 20.704C8.16959 22.1312 10.7504 22.7328 13.2976 22.3968C15.8432 22.0608 18.1808 20.8112 19.8752 18.88C21.568 16.9488 22.5024 14.4688 22.5024 11.8992C22.5024 10.0416 22.0128 8.21597 21.0832 6.60638ZM20.6144 16.9248C19.3968 19.0352 17.4528 20.6304 15.144 21.4144C12.8368 22.1984 10.3232 22.1152 8.07199 21.184C5.82079 20.2512 3.98559 18.5328 2.90719 16.3472C1.82879 14.1616 1.58239 11.6592 2.21279 9.30557C2.84319 6.95197 4.30719 4.90717 6.33279 3.55357C8.35839 2.19997 10.808 1.62717 13.224 1.94557C15.64 2.26237 17.8576 3.44797 19.4656 5.27997C21.0736 7.11197 21.96 9.46557 21.96 11.9024C21.96 13.6672 21.496 15.4 20.6144 16.928V16.9248Z"
      fill="#094F6F"
    />
    <path
      d="M16.4496 9.23357C15.5456 7.67197 14.6432 6.13917 13.8976 5.52957C13.3376 5.07357 12.6352 4.82397 11.912 4.82397C11.1888 4.82397 10.488 5.07357 9.92638 5.52957C9.18238 6.13917 8.27678 7.67197 7.37438 9.23357C6.46238 10.8112 5.59198 12.3872 5.43678 13.3456C5.33438 14.0432 5.47038 14.7568 5.82238 15.368C6.18238 15.9888 6.63358 16.3936 7.43838 16.7232C8.24318 17.0528 10.1696 17.0928 11.9104 17.0928C13.6512 17.0928 15.576 17.0512 16.3824 16.7232C17.1888 16.3952 17.6384 15.9936 17.9984 15.368C18.3504 14.7568 18.4848 14.0432 18.384 13.3456C18.2288 12.3872 17.3584 10.8112 16.4464 9.23357H16.4496ZM17.5424 15.1088C17.2368 15.6352 16.6656 16.0752 16.1696 16.2448C15.5488 16.456 14.5072 16.5488 11.9136 16.5488C9.31998 16.5488 8.27678 16.456 7.65758 16.2448C7.16158 16.0752 6.59038 15.6352 6.28478 15.1088C5.96798 14.5584 5.87358 13.8832 5.97278 13.3904C6.07518 12.8816 6.48798 11.856 7.84638 9.50397C9.21918 7.12637 9.79358 6.33597 10.2368 5.97277C10.7712 5.53437 11.3056 5.36637 11.9136 5.36637C12.5216 5.36637 13.056 5.53597 13.5904 5.97277C14.0336 6.33597 14.6064 7.12637 15.9808 9.50397C17.3392 11.856 17.752 12.88 17.8544 13.3904C17.9552 13.8832 17.8592 14.56 17.5424 15.1088Z"
      fill="#689FC3"
    />
    <path
      d="M12.8385 6.52639C12.7441 6.36479 12.6097 6.22879 12.4481 6.13599C12.2865 6.04159 12.1025 5.99359 11.9137 5.99359C11.7249 5.99359 11.5425 6.04319 11.3793 6.13599C11.2177 6.23039 11.0817 6.36479 10.9889 6.52639L6.85126 13.6896C6.75846 13.8512 6.70886 14.0368 6.70886 14.224C6.70886 14.4112 6.75846 14.5952 6.85126 14.7584C6.94566 14.92 7.08006 15.056 7.24166 15.1488C7.40326 15.2432 7.58726 15.2928 7.77606 15.2928H16.0497C16.2369 15.2928 16.4209 15.2432 16.5841 15.1488C16.7457 15.0544 16.8817 14.92 16.9745 14.7584C17.0689 14.5968 17.1169 14.4112 17.1169 14.224C17.1169 14.0368 17.0673 13.8528 16.9745 13.6896L12.8369 6.52639H12.8385ZM16.5057 14.488C16.4593 14.568 16.3937 14.6336 16.3137 14.68C16.2337 14.7264 16.1441 14.7504 16.0513 14.7504H7.77766C7.68486 14.7504 7.59366 14.7264 7.51366 14.68C7.43366 14.6336 7.36646 14.5664 7.32006 14.4864C7.27366 14.4064 7.24966 14.3152 7.24966 14.2224C7.24966 14.1296 7.27526 14.0384 7.32166 13.9584L11.4577 6.79519C11.5041 6.71519 11.5697 6.64959 11.6497 6.60319C11.7297 6.55679 11.8209 6.53279 11.9121 6.53279C12.0033 6.53279 12.0945 6.55679 12.1745 6.60319C12.2545 6.64959 12.3201 6.71519 12.3665 6.79519L16.5025 13.9584C16.5489 14.0384 16.5729 14.1296 16.5729 14.2224C16.5729 14.3152 16.5489 14.4064 16.5025 14.4864L16.5057 14.488Z"
      fill="#FD7B35"
    />
    <path
      d="M17.9937 8.3568C16.8577 6.3888 15.9345 5.2032 14.9889 4.5888C13.9729 3.9296 12.9953 3.6736 11.9137 3.672C10.8305 3.672 9.85127 3.9312 8.83847 4.5888C7.89287 5.2032 6.96967 6.3888 5.83367 8.3568C4.68807 10.3392 4.13127 11.7584 4.07207 12.8624C4.00967 13.9888 4.24807 15.0224 4.80007 15.976C5.34407 16.9232 6.16007 17.6864 7.14087 18.168C7.95687 18.6336 9.65127 18.8896 11.9153 18.8896C14.1793 18.8896 15.8737 18.6336 16.6897 18.168C17.6705 17.688 18.4865 16.9248 19.0305 15.976C19.5809 15.0224 19.8209 13.9872 19.7585 12.8624C19.6993 11.7584 19.1441 10.3392 17.9969 8.3568H17.9937ZM18.5585 15.704C18.0657 16.56 17.3281 17.2496 16.4401 17.6832C15.7201 18.0992 14.0593 18.3472 11.9137 18.3472C9.76807 18.3472 8.10567 18.1008 7.38567 17.6832C6.49767 17.2496 5.76007 16.56 5.26727 15.704C4.77127 14.8432 4.54887 13.9376 4.61287 12.88C4.67687 11.8224 5.22087 10.5008 6.30247 8.6272C7.37607 6.768 8.29447 5.6144 9.12647 5.048C9.95047 4.4912 10.9217 4.1936 11.9153 4.1936C12.9089 4.1936 13.8801 4.4912 14.7041 5.048C15.5345 5.6128 16.4545 6.768 17.5281 8.6272C18.6097 10.5008 19.1537 11.824 19.2177 12.88C19.2817 13.9376 19.0577 14.8416 18.5617 15.704H18.5585Z"
      fill="#4885A7"
    />
    <path
      d="M21.1296 12.3808C21.1296 10.9968 20.5648 9.25757 19.5392 7.48157C18.5136 5.70557 17.2896 4.34557 16.0976 3.65757C14.8336 2.90237 13.3872 2.50397 11.9152 2.50397C10.4432 2.50397 8.99678 2.90237 7.73278 3.65757C6.54078 4.34557 5.31678 5.70397 4.28798 7.48157C3.26078 9.25757 2.70078 10.9952 2.70078 12.3808C2.66558 13.8544 3.03678 15.3088 3.77438 16.5856C4.51198 17.8624 5.58558 18.9104 6.87998 19.616C8.07838 20.3072 9.86718 20.6896 11.9152 20.6896C13.9632 20.6896 15.752 20.3088 16.9504 19.616C18.2448 18.9104 19.3184 17.8608 20.056 16.5856C20.7936 15.3088 21.1648 13.8544 21.1296 12.3808ZM19.5872 16.3136C18.8976 17.5072 17.8928 18.4864 16.6816 19.1456C15.5632 19.7904 13.8704 20.144 11.9136 20.144C9.95678 20.144 8.26398 19.7888 7.14558 19.144C5.93598 18.4864 4.93118 17.5072 4.24318 16.3168C3.55358 15.1248 3.20638 13.7664 3.23998 12.3904C3.23998 11.0912 3.77918 9.44957 4.75838 7.75517C5.73758 6.06077 6.89118 4.77277 8.00798 4.12797C9.18398 3.40797 10.536 3.02877 11.9136 3.02877C13.2912 3.02877 14.6432 3.40957 15.8192 4.12797C16.936 4.77277 18.0896 6.06077 19.0688 7.75517C20.048 9.44957 20.5872 11.0944 20.5872 12.3904C20.6208 13.7664 20.2752 15.1248 19.5872 16.3152V16.3136Z"
      fill="#29698B"
    />
  </SvgIcon>
)

export default Anchorage
