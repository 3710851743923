import SvgIcon from '@mui/material/SvgIcon'

export const Usd = (props) => (
  <SvgIcon {...props} viewBox="0 0 36 36">
    <g clipPath="url(#clip0_1353_8588)">
      <path
        d="M18 0C8.05982 0 0 8.05982 0 18C0 27.9402 8.05982 36 18 36C27.9402 36 36 27.9402 36 18C36 8.05982 27.9402 0 18 0ZM18 32.9464C9.74732 32.9464 3.05357 26.2527 3.05357 18C3.05357 9.74732 9.74732 3.05357 18 3.05357C26.2527 3.05357 32.9464 9.74732 32.9464 18C32.9464 26.2527 26.2527 32.9464 18 32.9464ZM19.9165 17.0679L18.896 16.8308V11.4348C20.4228 11.6438 21.367 12.6 21.5277 13.7732C21.5478 13.9339 21.6844 14.0504 21.8451 14.0504H23.6491C23.8379 14.0504 23.9866 13.8857 23.9705 13.6969C23.7254 11.1937 21.6643 9.58661 18.9121 9.30938V7.99554C18.9121 7.81875 18.7674 7.67411 18.5906 7.67411H17.4616C17.2848 7.67411 17.1402 7.81875 17.1402 7.99554V9.32143C14.2955 9.59866 12.0696 11.1696 12.0696 14.1027C12.0696 16.8188 14.0705 18.1286 16.1719 18.6308L17.1643 18.8839V24.6174C15.3884 24.3804 14.392 23.4321 14.1871 22.1545C14.1629 22.0018 14.0263 21.8893 13.8696 21.8893H12.0134C11.8246 21.8893 11.6759 22.05 11.692 22.2388C11.8728 24.4487 13.5482 26.4817 17.1241 26.7429V28.0045C17.1241 28.1812 17.2688 28.3259 17.4455 28.3259H18.5866C18.7634 28.3259 18.908 28.1813 18.908 28.0004L18.9 26.7268C22.046 26.4496 24.296 24.7661 24.296 21.7446C24.292 18.9562 22.5201 17.7107 19.9165 17.0679ZM17.1603 16.417C16.9353 16.3527 16.7464 16.2924 16.5576 16.2161C15.1996 15.7259 14.5688 14.9344 14.5688 13.9138C14.5688 12.4554 15.6737 11.6237 17.1603 11.4348V16.417ZM18.896 24.6295V19.2576C19.0205 19.2937 19.133 19.3219 19.2496 19.346C21.15 19.9246 21.7888 20.7281 21.7888 21.9616C21.7888 23.5326 20.6076 24.4768 18.896 24.6295Z"
        fill="#AFAFC6"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1353_8588">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default Usd
