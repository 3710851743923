const LOGIN_STEPS = {
  USER_PASSWORD: 0,
  MFA: 1,
  NEW_PASSWORD: 2,
  SAVE_MFA: 3,
  FORGOT_PASSWORD: 4,
  VERIFICATION_CODE: 5,
  SSO_CALLBACK: 6,
}

export default LOGIN_STEPS
