// third-party
import { combineReducers } from 'redux'

// project import
import menu from './menu'
import snackbar from './snackbar'
import { merklebaseAPI } from '../api/merklebaseAPI'
import { ssoAPI } from 'store/api/ssoAPI'

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  [merklebaseAPI.reducerPath]: merklebaseAPI.reducer,
  [ssoAPI.reducerPath]: ssoAPI.reducer,
})

export default reducers
