export const getSsoLoginUrl = (ssoProvider) => {
  return `https://${process.env.REACT_APP_COGNITO_PREFIX ?? 'merklebaseauth'}.auth.${
    process.env.REACT_APP_REGION
  }.amazoncognito.com/oauth2/authorize?response_type=token&identity_provider=${ssoProvider}&client_id=${
    process.env.REACT_APP_USER_POOL_CLIENT_ID
  }&redirect_uri=${window.origin}/sso-callback&scope=email+openid`
}

export const getSsoLogoutUrl = () => {
  return `https://${process.env.REACT_APP_COGNITO_PREFIX ?? 'merklebaseauth'}.auth.${
    process.env.REACT_APP_REGION
  }.amazoncognito.com/logout?client_id=${process.env.REACT_APP_USER_POOL_CLIENT_ID}&logout_uri=${
    window.origin
  }/logout`
}
