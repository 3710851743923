// project import
import { ThemeMode } from 'config'

// ==============================|| OVERRIDES - INPUT BORDER & SHADOWS ||============================== //

function getColor({ theme }) {
  return {
    backgroundColor: theme.palette.secondary.light,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.divider}`,
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.error.main}`,
      boxShadow: `0px 0px 4px 0px ${theme.palette.error.main}`,
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: `0px 0px 4px 0px ${theme.palette.divider}`,
      },
    },
  }
}

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10.5px 14px 10.5px 12px',
          '&.Mui-disabled': {
            color: theme.palette.secondary.dark,
            WebkitTextFillColor: theme.palette.secondary.dark,
          },
        },
        notchedOutline: {
          borderColor:
            theme.palette.mode === ThemeMode.DARK
              ? theme.palette.grey[200]
              : theme.palette.grey[300],
        },
        root: {
          ...getColor({ variant: 'primary', theme }),
          '&.Mui-error': {
            ...getColor({ variant: 'error', theme }),
          },
        },
        inputSizeSmall: {
          padding: '7.5px 8px 7.5px 12px',
        },
        inputMultiline: {
          padding: 0,
        },
        colorSecondary: getColor({ variant: 'secondary', theme }),
        colorError: getColor({ variant: 'error', theme }),
        colorWarning: getColor({ variant: 'warning', theme }),
        colorInfo: getColor({ variant: 'info', theme }),
        colorSuccess: getColor({ variant: 'success', theme }),
      },
    },
  }
}
