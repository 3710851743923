import SvgIcon from '@mui/material/SvgIcon'

export const Gateio = (props) => (
  <SvgIcon {...props} viewBox="0 0 600 600">
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="换色"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="SVG" transform="translate(-1755.000000, -5518.000000)">
        <g id="编组-7" transform="translate(1755.000000, 5518.000000)">
          <g id="编组">
            <g id="Clip-2" />
            <path
              d="M300,464.998427 C208.873069,464.998427 134.996504,391.122566 134.996504,299.996504 C134.996504,208.870442 208.873069,135.000938 300,135.000938 L300,-0.000635600624 C134.310049,-0.000635600624 0,134.314489 0,299.996504 C0,465.67852 134.310049,600 300,600 C465.689951,600 600,465.67852 600,299.996504 L465.003496,299.996504 C465.003496,391.122566 391.126931,464.998427 300,464.998427"
              id="Fill-1"
              fill="#2354E6"
              mask="url(#mask-2)"
            />
          </g>
          <polygon
            id="Fill-3"
            fill="#17E6A1"
            points="299.992373 299.99714 464.995869 299.99714 464.995869 134.995217 299.992373 134.995217"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Gateio
