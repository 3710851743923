import { ThemeMode } from 'config'
import PropTypes from 'prop-types'

// ==============================|| PRESET THEME - DEFAULT ||============================== //

const Default = (colors, mode) => {
  const greyColors =
    mode === ThemeMode.DARK
      ? {
          0: '#111111',
          100: '#131417',
          200: '#1B1D21',
          300: '#292929',
          400: '#323437',
          500: '#494F5A',
          600: '#ADAEAF',
          700: '#E4E4E4',
          800: '#F0F0F0',
          900: '#F5F5F5',
          A100: '#FFFFFF',
        }
      : {
          0: '#FFFFFF',
          100: '#F5F5F5',
          200: '#F0F0F0',
          300: '#E4E4E4',
          400: '#ADAEAF',
          500: '#494F5A',
          600: '#323437',
          700: '#292929',
          800: '#1B1D21',
          900: '#131417',
          A100: '#111111',
        }

  const brandColors =
    mode === ThemeMode.DARK
      ? {
          300: '#1F1F1F',
          400: '#10646F',
          500: '#137886',
          600: '#168898',
          700: '#1DB9CD',
          800: '#98E6F0',
        }
      : {
          300: '#98E6F0',
          400: '#1DB9CD',
          500: '#168898',
          600: '#137886',
          700: '#10646F',
          800: '#1F1F1F',
        }

  const redColors =
    mode === ThemeMode.DARK
      ? {
          100: '#C1390B',
          300: '#FF6C5C',
          600: '#FFEDEB',
        }
      : {
          100: '#FFEDEB',
          300: '#FF6C5C',
          600: '#C1390B',
        }

  const greenColors =
    mode === ThemeMode.DARK
      ? {
          100: '#3A822B',
          300: '#8ACB88',
          600: '#F1F9F1',
        }
      : {
          100: '#F1F9F1',
          300: '#8ACB88',
          600: '#3A822B',
        }

  const blueColors =
    mode === ThemeMode.DARK
      ? {
          300: '#0D3969',
          400: '#13579F',
          500: '#1A75D6',
          600: '#4392E8',
          700: '#6DC0F8',
        }
      : {
          300: '#6DC0F8',
          400: '#4392E8',
          500: '#1A75D6',
          600: '#13579F',
          700: '#0D3969',
        }

  const purpleColors =
    mode === ThemeMode.DARK
      ? {
          300: '#553CB9',
          400: '#8C5383',
          500: '#704269',
          600: '#AB8EDB',
          700: '#E3C9F8',
        }
      : {
          300: '#E3C9F8',
          400: '#AB8EDB',
          500: '#704269',
          600: '#8C5383',
          700: '#553CB9',
        }

  const pinkColors =
    mode === ThemeMode.DARK
      ? {
          300: '#A63573',
          400: '#AA6FA1',
          500: '#DD94BC',
          600: '#FFD3DB',
        }
      : {
          300: '#FFD3DB',
          400: '#DD94BC',
          500: '#AA6FA1',
          600: '#A63573',
        }

  const yellowColors =
    mode === ThemeMode.DARK
      ? {
          300: '#752B0B',
          400: '#A64E30',
          500: '#FDD870',
          600: '#D37E41',
          700: '#F1C09D',
          800: '#FFFCD6',
        }
      : {
          300: '#FFFCD6',
          400: '#F1C09D',
          500: '#D37E41',
          600: '#6D700A',
          700: '#A64E30',
          800: '#752B0B',
        }

  const contrastText = '#000000'

  return {
    primary: {
      lighter: brandColors[300],
      light: brandColors[400],
      main: brandColors[500],
      dark: brandColors[600],
      darker: brandColors[800],
      contrastText,
    },
    secondary: {
      lighter: greyColors[0],
      light: greyColors[400],
      main: greyColors[500],
      dark: greyColors[600],
    },
    error: {
      light: redColors[100],
      main: redColors[300],
      dark: redColors[600],
      darker: redColors[600],
    },
    warning: {
      light: yellowColors[300],
      main: yellowColors[500],
      dark: yellowColors[700],
    },
    info: {
      light: blueColors[700],
      main: blueColors[400],
      dark: blueColors[300],
    },
    success: {
      lighter: greenColors[100],
      light: greenColors[100],
      main: greenColors[300],
      dark: greenColors[300],
      darker: greenColors[600],
    },
    onHold: {
      main: '#FDC74D',
    },

    grey: greyColors,
    blue: blueColors,
    brand: brandColors,
    spacing: (factor) => `${0.25 * factor}rem`,

    charts: {
      400: blueColors[700], // 1      #6DC0F8
      light: blueColors[600], // 2    #4392E8
      dark: pinkColors[500], // 3     #DD94BC
      darker: purpleColors[700], // 4 #E3C9F8
      main: brandColors[800], // 5    #98E6F0
      200: brandColors[700], // 6     #1DB9CD
      lighter: brandColors[600], // 7 #168898
      800: purpleColors[600], // 8    #AB8EDB
      A200: pinkColors[600], // 9     #FFD3DB
      A300: yellowColors[800], // 10  #FFFCD6
      A100: yellowColors[700], // 11  #F1C09D
      600: yellowColors[600], // 12   #D37E41
      A400: greyColors[600], // 13    #494F5A
    },
    kak: {
      longShortPositions: ['#A4E2A2', '#FF6C5C'],
      historyVar: {
        95: '#33A8F6',
        975: '#AB8EDB',
        99: '#D37E41',
      },
    },
    transactions: {
      side: {
        buy: greenColors[300],
        sell: redColors[300],
      },
    },
  }
}

Default.propTypes = {
  colors: PropTypes.object,
}

export default Default
