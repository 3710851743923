import SvgIcon from '@mui/material/SvgIcon'

export const Kucoin = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <g
      id="页面-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      transform="matrix(1, 0, 0, 1, 165.554031, 275.675293)"
    >
      <g id="导航栏下拉最小宽度备份-4" transform="translate(-46.000000, -26.000000)">
        <g id="编组-27备份-2">
          <g id="kucoin-logo" transform="translate(32.000000, 12.000000)">
            <rect id="矩形" x="0" y="0" width="150" height="56"></rect>
            <g
              id="KUCOIN-logo-3.0"
              stroke-width="1"
              fill-rule="evenodd"
              transform="translate(14.000000, 14.000000)"
              fill="#23AF91"
            >
              <path
                d="M -8.235 -24.693 L 151.305 122.336 L 252.007 29.545 C 270.013 14.606 297.561 15.27 314.695 31.058 C 331.829 46.847 332.55 72.232 316.335 88.822 L 183.444 211.299 C 165.593 227.474 136.964 227.474 119.114 211.299 L -72.616 34.584 L -72.616 139.619 C -72.616 162.799 -93.008 181.591 -118.167 181.591 C -143.324 181.591 -163.717 162.799 -163.717 139.619 L -163.717 -189.141 C -163.717 -212.322 -143.324 -231.114 -118.167 -231.114 C -93.008 -231.114 -72.616 -212.322 -72.616 -189.141 L -72.616 -84.105 L 119.09 -260.775 C 136.934 -276.963 165.574 -276.963 183.419 -260.775 L 316.409 -138.323 C 332.625 -121.728 331.902 -96.344 314.769 -80.557 C 297.635 -64.769 270.086 -64.105 252.08 -79.044 L 151.378 -171.836 L -8.235 -24.693 Z M 151.378 -66.709 C 132.933 -66.72 116.298 -56.487 109.23 -40.788 C 102.166 -25.088 106.06 -7.01 119.101 5.012 C 132.139 17.034 151.755 20.631 168.796 14.13 C 185.841 7.626 196.953 -7.696 196.953 -24.693 C 196.959 -35.836 192.162 -46.526 183.615 -54.41 C 175.068 -62.295 163.472 -66.728 151.378 -66.733 L 151.378 -66.709 Z"
                id="形状"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Kucoin
