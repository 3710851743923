import { useMemo } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { AppBar, Toolbar, useMediaQuery } from '@mui/material'

// project import
import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'
import IconButton from 'components/@extended/IconButton'

import { MenuOrientation, ThemeMode } from 'config'
import useConfig from 'hooks/useConfig'
import { dispatch, useSelector } from 'store'
import { openDrawer } from 'store/reducers/menu'

// assets
import { LeftSquareOutlined, RightSquareOutlined } from '@ant-design/icons'

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
  const theme = useTheme()
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))
  const { menuOrientation } = useConfig()

  const menu = useSelector((state) => state.menu)
  const { drawerOpen } = menu

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG

  // header content
  const headerContent = useMemo(() => <HeaderContent />, [])

  // const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300'
  // const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100'

  // common header
  const mainHeader = (
    <Toolbar>
      {!isHorizontal ? (
        <IconButton
          aria-label="open drawer"
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          edge="start"
          color="secondary"
          variant="text"
          sx={{
            bgcolor: 'transparent',
            ml: { xs: 0, lg: -2 },
          }}
        >
          {!drawerOpen ? <RightSquareOutlined /> : <LeftSquareOutlined />}
        </IconButton>
      ) : null}
      {headerContent}
    </Toolbar>
  )

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      bgcolor: theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.50',
      zIndex: 1200,
      width: isHorizontal
        ? '100%'
        : drawerOpen
        ? 'calc(100% - 260px)'
        : { xs: '100%', lg: 'calc(100% - 60px)' },
      // boxShadow: theme.customShadows.z1
    },
  }

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={drawerOpen} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  )
}

export default Header
