import SvgIcon from '@mui/material/SvgIcon'

export const Deribit = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_1336_33077)">
      <path
        d="M24 11.8441C23.917 8.27742 22.2179 6.28411 20.5403 5.1818C19.196 4.31396 17.6297 3.85801 16.0321 3.86953H15.6305V0H11.8494V3.86953H7.73222V0H3.94979V3.86953H0L0.0174059 7.84H3.93372V15.6638H0V19.5159H3.94979V23.52H7.73222V19.5159H11.8494V23.52H15.6318V19.5159H16.3013C17.6205 19.5238 18.9207 19.1999 20.0837 18.5737C24.1004 16.3799 24 11.8441 24 11.8441ZM19.69 13.6503C19.4444 14.1325 19.0982 14.5558 18.6751 14.8913C18.0012 15.4111 17.1734 15.6888 16.324 15.68H7.74293V7.85077L16.3468 7.82923C16.7503 7.82486 17.1523 7.88019 17.5397 7.99344C17.9872 8.12581 18.4043 8.34581 18.7668 8.64077C19.1294 8.93574 19.4304 9.29986 19.6526 9.71218C19.9809 10.3148 20.1561 10.9895 20.1626 11.6765C20.1692 12.3636 20.0068 13.0416 19.69 13.6503Z"
        fill="#2DAE9A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1336_33077">
        <rect width="24" height="23.52" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default Deribit
