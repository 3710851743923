import SvgIcon from '@mui/material/SvgIcon'

export const Mexc = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <path
      d="M 485.232 324.463 L 379.622 95.624 C 356.47 47.928 300.011 47.42 277.67 97.653 L 166.781 335.625 C 146.065 379.262 171.655 434.061 213.085 434.061 L 435.271 434.061 C 477.108 434.061 508.385 377.739 485.232 324.463 Z"
      fill="#3156AA"
    ></path>
    <path
      d="M 332.927 342.229 L 326.427 328.022 C 320.335 314.831 306.93 286.416 306.93 286.416 L 217.569 92.588 C 194.416 49.457 139.58 45.908 116.43 100.198 L 12.444 324.978 C -9.082 372.166 16.507 433.561 63.217 434.068 L 284.184 434.068 L 378.418 434.068 L 434.471 434.068 C 377.201 434.576 358.921 397.536 332.927 342.229 Z"
      fill="#1972E2"
    ></path>
    <path
      d="M 332.929 342.227 L 326.429 328.02 C 320.337 314.826 306.933 286.414 306.933 286.414 L 249.255 159.562 L 166.393 336.139 C 145.677 379.773 171.266 434.574 212.698 434.574 L 283.78 434.574 L 378.018 434.574 L 434.476 434.574 C 376.798 434.066 358.925 397.534 332.929 342.227 Z"
      fill="url(#paint0_linear_5415_38335)"
    ></path>
  </SvgIcon>
)

export default Mexc
