import SvgIcon from '@mui/material/SvgIcon'

export const DefaultProviderIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 25 24">
    <path
      d="M24.391 14.9029C22.7881 21.3315 16.2762 25.2439 9.84606 23.6408C3.41863 22.0381 -0.494149 15.5265 1.10942 9.0984C2.71159 2.66906 9.22349 -1.24358 15.6516 0.359111C22.0813 1.9618 25.9938 8.47408 24.3908 14.903L24.3909 14.9029H24.391Z"
      fill="#F7931A"
    />
    <path
      d="M18.0421 10.2903C18.281 8.69337 17.065 7.83496 15.4023 7.26231L15.9417 5.09911L14.6248 4.771L14.0997 6.87724C13.7535 6.7909 13.3979 6.70954 13.0445 6.62889L13.5734 4.50875L12.2573 4.18064L11.7176 6.34313C11.4311 6.2779 11.1497 6.21344 10.8767 6.14552L10.8782 6.13871L9.06212 5.68525L8.7118 7.09168C8.7118 7.09168 9.68886 7.31562 9.66827 7.32941C10.2016 7.4625 10.298 7.81548 10.282 8.09527L9.66757 10.5597C9.70429 10.569 9.75193 10.5825 9.80449 10.6036C9.76055 10.5927 9.7138 10.5808 9.66528 10.5692L8.80407 14.0214C8.7389 14.1834 8.57347 14.4265 8.20062 14.3342C8.21382 14.3533 7.24344 14.0954 7.24344 14.0954L6.5896 15.6027L8.3034 16.0299C8.62222 16.1098 8.93465 16.1935 9.24233 16.2721L8.69737 18.4601L10.0128 18.7882L10.5525 16.6235C10.9118 16.721 11.2606 16.811 11.602 16.8958L11.0641 19.0504L12.3811 19.3785L12.926 17.1946C15.1717 17.6195 16.8603 17.4482 17.571 15.4172C18.1438 13.782 17.5425 12.8389 16.3611 12.2238C17.2216 12.0254 17.8697 11.4595 18.0426 10.2905L18.0421 10.2902L18.0421 10.2903ZM15.0332 14.5091C14.6262 16.1443 11.8727 15.2604 10.98 15.0387L11.7032 12.14C12.5958 12.3628 15.4585 12.8038 15.0332 14.5091H15.0332ZM15.4405 10.2666C15.0692 11.754 12.7775 10.9983 12.034 10.813L12.6897 8.18407C13.4331 8.36936 15.8272 8.71519 15.4406 10.2666H15.4405Z"
      fill="white"
    />
  </SvgIcon>
)

export default DefaultProviderIcon
