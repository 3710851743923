import { organizationsTypes, riskVarTypes, riskBetaTypes } from 'merklebase-utils/enums'

export const mapUser = ({ user }) => ({
  id: user.id,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  role: user.role,
  view: JSON.parse(user.views),
  organizationId: user.organization_id,
  organizationName: user.organization_name,
  featureFlags: user.feature_flags,
  preferences: {
    organizationType: {
      id: user.organization_type_id,
      name: Object.keys(organizationsTypes).find(
        (ot) => organizationsTypes[ot] === user.organization_type_id
      ),
    },
    timezone: user.timezone,
    riskVarType: {
      id: user.risk_var_type_id,
      name: Object.keys(riskVarTypes).find((rvt) => riskVarTypes[rvt] === user.risk_var_type_id),
    },
    riskBetaType: {
      id: user.risk_beta_type_id,
      name: Object.keys(riskBetaTypes).find((rbt) => riskBetaTypes[rbt] === user.risk_beta_type_id),
    },
    closePriceHour: Number(user.close_hour),
    closeActivityHour: Number(user.close_activity_hour),
  },
  portfoliosIds: user.portfolios_ids || [],
})
