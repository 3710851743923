// ==============================|| OVERRIDES - DIALOG ||============================== //

export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.background.default,
          backgroundImage: 'none',
          border: `1px solid ${theme.palette.border.primary}`,
          boxShadow: '-3px 0px 10px 0px #00000080, -1px -1px 1px 0px #D5D5EB1A',
          '& .MuiCardHeader-root': {
            color: theme.palette.text.primary,
            height: theme.spacing(6),
            backgroundColor: theme.palette.card.header.background,
          },
        },
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: theme.palette.dialog.backDropColor,
          },
          '& .MuiDialogTitle-root': {
            border: `1px solid ${theme.palette.border.primary}`,
            height: theme.spacing(8),
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.dialog.header.background,
            '& .MuiButtonBase-root': {
              color: theme.palette.text.secondary,
            },
          },
        },
      },
    },
  }
}
