import ArrowUp from './ArrowUp'
import ArrowDown from './ArrowDown'
import DefaultProviderIcon from './DefaultProviderIcon'
import Anchorage from './Anchorage'
import Coinbaseprime from './Coinbaseprime'
import Coinbasepro from './Coinbasepro'
import Copper from './Copper'
import Deribit from './Deribit'
import Fireblocks from './Fireblocks'
import Success from './Success'
import Link from './Link'
import Error from './Error'
import Sync from './Sync'
import Refresh from './Refresh'
import Remove from './Remove'
import Rename from './Rename'
import Binance from './Binance'
import Binancepm from './Binancepm'
import Bitfinex from './Bitfinex'
import Kucoin from './Kucoin'
import Bitget from './Bitget'
import Bitmex from './Bitmex'
import Bitgo from './Bitgo'
import Bitstamp from './Bitstamp'
import Bybit from './Bybit'
import Gemini from './Gemini'
import Huobi from './Huobi'
import Kcs from './Kcs'
import Kraken from './Kraken'
import Mexc from './Mexc'
import Okx from './Okx'
import Bingx from './Bingx'
import Gateio from './Gate-io'
import Cryptocom from './Cryptocom'
import Crypto from './Crypto'
import Usd from './Usd'
import Columns from './Columns'
import Falconx from './Falconx'
import DefaultLinkProvider from './DefaultLinkProvider'
import Merklebase from './Merklebase'
import Ethereum from './Ethereum'
import Polygon from './Polygon'
import Bnb from './Bnb'
import Arbitrumone from './Arbitrumone'
import Optimism from './Optimism'
import Avalanche from './Avalanche'
import Gnosis from './Gnosis'
import Celo from './Celo'
import Moonbeam from './Moonbeam'
import Reconnect from './Reconnect'
import Portfolio from './Portfolio'
import B2c2 from './B2c2'
import B2c2spot from './B2c2spot'
import Amber from './Amber'
import Defi from './Defi'
import Base from './Base'
import DefiWallet from './DefiWallet'

export {
  Anchorage,
  Coinbaseprime,
  Coinbasepro,
  Copper,
  Deribit,
  Fireblocks,
  ArrowUp,
  ArrowDown,
  DefaultProviderIcon,
  Success,
  Kucoin,
  Link,
  Error,
  Sync,
  Refresh,
  Remove,
  Rename,
  Binance,
  Binancepm,
  Bitfinex,
  Bitmex,
  Bitstamp,
  Bybit,
  Mexc,
  Gemini,
  Huobi,
  Kcs,
  Kraken,
  Okx,
  Bitget,
  Bingx,
  Gateio,
  Cryptocom,
  Bitgo,
  Crypto,
  Usd,
  Columns,
  Falconx,
  DefaultLinkProvider,
  Merklebase,
  Ethereum,
  Polygon,
  Bnb,
  Arbitrumone,
  Optimism,
  Avalanche,
  Gnosis,
  Celo,
  Moonbeam,
  Reconnect,
  Portfolio,
  B2c2,
  B2c2spot,
  Amber,
}

const icons = {
  Anchorage,
  Coinbaseprime,
  Coinbasepro,
  Copper,
  Deribit,
  Fireblocks,
  ArrowUp,
  ArrowDown,
  DefaultProviderIcon,
  Success,
  Error,
  Mexc,
  Kucoin,
  Link,
  Sync,
  Refresh,
  Remove,
  Rename,
  Binance,
  Binancepm,
  Bitfinex,
  Bitmex,
  Bitstamp,
  Bybit,
  Gemini,
  Huobi,
  Kcs,
  Kraken,
  Okx,
  Bitget,
  Bingx,
  Gateio,
  Cryptocom,
  Bitgo,
  Reconnect,
  Crypto,
  Usd,
  Columns,
  Falconx,
  DefaultLinkProvider,
  Merklebase,
  Ethereum,
  Polygon,
  Bnb,
  Arbitrumone,
  Optimism,
  Avalanche,
  Gnosis,
  Celo,
  Moonbeam,
  Portfolio,
  B2c2,
  B2c2spot,
  Amber,
  Defi,
  Base,
  DefiWallet,
}

export default icons
