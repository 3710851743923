import SvgIcon from '@mui/material/SvgIcon'

export const Cryptocom = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <g id="Layer_x0020_1" transform="matrix(0.21582, 0, 0, 0.199263, -20.209261, 0.616785)">
      <rect y="0" class="st0" width="2500" height="2500" fill=" none"></rect>
      <g id="_2495404453392">
        <path
          d="M1250,0L167.1,625v1250L1250,2500l1082.9-625V625L1250,0L1250,0z M1250,0L167.1,625v1250L1250,2500    l1082.9-625V625L1250,0L1250,0z"
          fill=" rgb(255, 255, 255)"
        ></path>
        <path
          d="M1677.5,2011.7h-154.1L1339,1842.4v-86.8l191-182.3v-288.6l249.6-162.8l284.3,214.8L1677.5,2011.7    L1677.5,2011.7z M1039.5,1556l28.2-271.3l-93.3-243.1h551.2l-91.2,243.1l26.1,271.3h-212.7H1039.5L1039.5,1556z M1165.4,1842.4    l-184.5,171.4H824.6l-388.5-677.1l286.5-212.7l251.7,160.6v288.6l191,182.3L1165.4,1842.4L1165.4,1842.4z M822.5,540.4h852.9    l102,434H722.7L822.5,540.4L822.5,540.4z M1250,0L167.1,625v1250L1250,2500l1082.9-625V625L1250,0L1250,0z"
          fill=" rgb(3, 49, 108)"
        ></path>
      </g>
    </g>
  </SvgIcon>
)

export default Cryptocom
