// ==============================|| OVERRIDES - TOGGLE BUTTON ||============================== //

export default function ToggleButtonGroup(theme) {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          padding: 4,
          borderRadius: 8,
          backgroundColor: theme.palette.grey[100],
          border: `1px solid ${theme.palette.secondary.light}`,
          '& .MuiToggleButtonGroup-firstButton': {
            borderRadius: 4,
            marginRight: 1,
          },
          '& .MuiToggleButtonGroup-middleButton': {
            borderRadius: 4,
            marginRight: 1,
          },
          '& .MuiToggleButtonGroup-lastButton': {
            borderRadius: 4,
            marginRight: 0,
          },
        },
      },
    },
  }
}
