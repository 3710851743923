import SvgIcon from '@mui/material/SvgIcon'

export const Merklebase = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12C23.5 18.3513 18.3513 23.5 12 23.5Z"
      fill="#221F38"
      stroke="url(#paint0_linear_1840_103198)"
    />
    <path
      d="M17.4706 12.7809C17.2381 12.2557 16.8175 11.8413 16.3137 11.5764C16.7297 11.4508 17.0174 11.0676 17.2098 10.6951C17.5096 9.7525 17.2843 8.51813 16.3839 7.98242C16.0828 8.39388 15.7313 8.86917 15.436 9.28658C15.6061 9.46962 15.7319 9.64127 15.7812 9.89772C15.8432 10.2201 15.6631 10.5784 15.3784 10.7416C15.0412 10.9349 14.6229 10.9655 14.2238 10.9404C14.167 11.0181 14.0553 11.1738 13.9985 11.2516C13.7138 11.627 13.4275 11.9997 13.1606 12.3886C14.0074 12.4399 15.0814 12.1773 15.755 12.8518C15.9718 13.0689 16.0415 13.473 15.9706 13.7625C15.7547 14.6425 14.4948 14.5706 13.8116 14.5545C13.6999 14.552 13.588 14.5499 13.4763 14.5517C12.8542 14.5617 12.2323 14.5825 11.6102 14.5902C11.5854 14.5905 11.5606 14.5909 11.5355 14.5912C11.1985 15.0924 10.8363 15.5773 10.5201 16.0934C12.0484 16.1039 13.5766 16.1039 15.1048 16.095C15.7828 16.095 17.5957 15.4223 17.5957 13.9153C17.6147 13.3116 17.5665 13.0748 17.4711 12.7807L17.4706 12.7809Z"
      fill="url(#paint1_linear_1840_103198)"
    />
    <path
      d="M13.4103 7.68066H15.404L11.0398 13.6757H10.6607L8.06515 10.2648V16.1402H6.3999V7.68066H8.28649L10.8369 11.1869L13.4103 7.68066Z"
      fill="url(#paint2_linear_1840_103198)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1840_103198"
        x1="12"
        y1="0"
        x2="12"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5D5EB" />
        <stop offset="1" stopColor="#AFAFC6" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1840_103198"
        x1="14.0599"
        y1="8.0765"
        x2="14.0599"
        y2="15.6125"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#79E7DE" />
        <stop offset="0.88" stopColor="#1896A7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1840_103198"
        x1="10.9019"
        y1="7.77868"
        x2="10.9019"
        y2="15.6307"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#79E7DE" />
        <stop offset="0.88" stopColor="#1896A7" />
      </linearGradient>
    </defs>
  </SvgIcon>
)

export default Merklebase
