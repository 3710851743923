import SvgIcon from '@mui/material/SvgIcon'

export const Portfolio = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M14.5 8.5V14H1.5V8.5M8 11V9M10 4C10 4 10 2 8 2C6 2 6 4 6 4M1 4H15V8C15 8 12 10 8 10C4 10 1 8 1 8V4Z"
      stroke="#D5D5EB"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
)

export default Portfolio
