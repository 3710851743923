// material-ui
import { createTheme } from '@mui/material/styles'

// third-party
import { presetDarkPalettes, presetPalettes } from '@ant-design/colors'

// project import
import ThemeOption from './theme'
import { ThemeMode } from 'config'

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode, presetColor) => {
  const colors = mode === ThemeMode.DARK ? presetDarkPalettes : presetPalettes

  let greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000',
  ]
  let greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f']
  let greyConstant = ['#fafafb', '#e6ebf1']

  if (mode === ThemeMode.DARK) {
    greyPrimary = [
      '#000000',
      '#141414',
      '#1e1e1e',
      '#595959',
      '#8c8c8c',
      '#bfbfbf',
      '#d9d9d9',
      '#f0f0f0',
      '#f5f5f5',
      '#fafafa',
      '#ffffff',
    ]
    // greyPrimary.reverse();
    greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f']
    greyConstant = ['#121212', '#d3d8db']
  }
  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant]

  const paletteColor = ThemeOption(colors, presetColor, mode)

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff',
      },
      trancheDirection: {
        B: '#FDE695',
        L: '#6DC0F8',
        S: '#FFFFFF',
        C: '#FFFFFF',
      },
      card: {
        header: {
          background: paletteColor.grey[300],
          number: {
            background: paletteColor.grey[500],
            color: paletteColor.grey['A100'],
          },
        },
      },
      dialog: {
        backDropColor: mode === ThemeMode.DARK ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        header: {
          background: paletteColor.grey[200],
        },
      },
      ...paletteColor,
      link: {
        primary: paletteColor.primary.main,
      },
      border: {
        primary: paletteColor.grey[400],
      },
      text: {
        primary: paletteColor.grey['A100'],
        secondary: paletteColor.grey[700],
        active: paletteColor.primary.darker,
        disabled: paletteColor.grey[500],
      },
      action: {
        disabled: paletteColor.grey[300],
      },
      divider: paletteColor.grey[400],
      background: {
        default: mode === ThemeMode.DARK ? paletteColor.grey[0] : paletteColor.grey[200],
        paper: mode === ThemeMode.DARK ? paletteColor.grey[200] : paletteColor.grey[0],
        active: paletteColor.grey[400],
      },
    },
  })
}

export default Palette
