import SvgIcon from '@mui/material/SvgIcon'

export const Bingx = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <g transform="matrix(4.34798, 0, 0, 4.33333, 234.176668, 135.703906)">
      <path
        d="M 60.15 -29.983 C 47.048 -29.983 34.095 -25.839 22.682 -17.993 C 16.569 -13.793 10.926 -8.582 5.911 -2.496 C 4.731 -1.071 3.575 0.42 2.469 1.943 C -1.229 7.039 -4.508 12.633 -7.262 18.563 C -8.239 20.692 -9.324 22.769 -10.484 24.752 C -10.637 25.012 -10.782 25.271 -10.947 25.524 C -21.088 42.31 -36.539 51.934 -53.335 51.934 L -53.335 82.948 C -40.232 82.948 -27.279 78.805 -15.867 70.963 C -9.767 66.773 -4.123 61.552 0.904 55.467 C 2.084 54.037 3.239 52.545 4.35 51.028 C 8.052 45.926 11.319 40.342 14.072 34.407 L 14.255 34.024 C 15.187 32.024 16.214 30.077 17.295 28.222 C 17.447 27.969 17.601 27.705 17.763 27.435 C 27.9 10.654 43.354 1.026 60.15 1.026 L 60.15 -29.983 Z"
        fill="#2954fe"
        fill-rule="nonzero"
      ></path>
      <path
        d="M 60.15 51.944 C 43.354 51.944 27.9 42.316 17.755 25.529 C 17.605 25.276 17.447 25.022 17.295 24.747 C 16.139 22.779 15.055 20.698 14.076 18.563 C 11.323 12.639 8.047 7.05 4.346 1.948 C 3.239 0.436 2.084 -1.056 0.904 -2.486 C -4.111 -8.572 -9.751 -13.787 -15.867 -17.983 C -27.279 -25.824 -40.232 -29.968 -53.335 -29.968 L -53.335 1.046 C -36.539 1.046 -21.084 10.669 -10.939 27.456 C -10.782 27.71 -10.633 27.974 -10.48 28.228 C -9.39 30.087 -8.364 32.04 -7.444 34.019 L -7.249 34.423 C -4.5 40.342 -1.229 45.926 2.469 51.033 C 3.571 52.545 4.731 54.037 5.911 55.467 C 10.934 61.552 16.578 66.773 22.682 70.963 C 34.095 78.805 47.048 82.948 60.15 82.948 L 60.15 51.944 Z"
        fill="#2954fe"
        fill-rule="nonzero"
      ></path>
    </g>
  </SvgIcon>
)

export default Bingx
