import { useCallback, useRef, useState } from 'react'

export default function usePoll(callback, delay) {
  const timeoutRef = useRef()
  const [isPolling, setIsPolling] = useState(false)

  const stopPolling = useCallback(() => {
    clearTimeout(timeoutRef?.current)
    setIsPolling(false)
  }, [])

  const startPolling = useCallback(() => {
    const tick = () => {
      callback(stopPolling)
    }

    setIsPolling(true)
    timeoutRef.current = setInterval(tick, delay)
  }, [callback, delay, stopPolling])

  return {
    startPolling,
    stopPolling,
    isPolling,
  }
}
